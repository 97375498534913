<script setup>
import axios from 'axios'
import { reactive, onMounted } from 'vue'

const baseURL = import.meta.env.VITE_URL

const state = reactive([])

const getContent = () => {
  axios.get(`${baseURL}/api/impressums?populate=*`).then(async (res) => {
    // console.log(res.data.data)
    state.value = await res.data.data[0].attributes.section
  })
}

onMounted(getContent)
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 mx-auto" v-for="(section, index) in state.value" :key="index">
        <h4 class="lead font-helvetica-black" v-if="section.section_title" v-html="section.section_title"></h4>
        <h6 class="font-helvetica-black" v-if="section.paragraph_title" v-html="section.paragraph_title"></h6>
        <p v-if="section.text" v-html="section.text"></p>
      </div>
    </div>
  </div>
</template>
