<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 660 700"
    style="enable-background: new 0 0 660 700"
    xml:space="preserve"
  >
    <image
      style="overflow: visible; enable-background: new"
      width="650"
      height="366"
      class='level-1 show'
      id="level-1_00000115476413235681203300000017655058211731928747_"
      xlink:href="@/assets/img/Level-1-bg.png"

    ></image>
    <image
      style="overflow: visible; enable-background: new"
      width="400"
      height="170"
      class='level-2 show'
      id="level-2_00000064355383862072537230000009244107985235240358_"
      xlink:href="@/assets/img/Level-2-bg.png"

    ></image>
    <image
      style="overflow: visible"
      width="260"
      height="118"
      class='level-3-3 show'
      id="level-3-3"
      xlink:href="@/assets/img/Level-3-3-bg.png"

    ></image>
    <image
      style="overflow: visible"
      width="252"
      height="110"
      class='level-3-4 show'
      id="level-3-4"
      xlink:href="@/assets/img/Level-3-4-bg.png"

    ></image>
    <image
      style="overflow: visible"
      width="114"
      height="96"
      class='level-3-5 show'
      id="level-3-5"
      xlink:href="@/assets/img/Level-3-5-bg.png"

    ></image>
    <image
        style="overflow: visible; enable-background: new"
        width="210"
        height="128"
        class='level-3-1 show'
        id="level-3-1"
        xlink:href="@/assets/img/Level-3-1-bg.png"

    ></image>
    <image
        style="overflow: visible; enable-background: new"
        width="200"
        height="108"
        class='level-3-2 show'
        id="level-3-2"
        xlink:href="@/assets/img/Level-3-2-bg.png"

    ></image>
    <g id="level-1">
	<path id="Loft-Wohnung-06" class="st1" d="M635.4,452.7l-76.7-33l-60.4,24.9l68,31.9l-0.6,2.5l5,2.5l-4.5,2l-0.8,27.1l58.8-27.4
		l-2-0.8l2-23.8L635.4,452.7z"/>
      <path id="Loft-Wohnung-05" class="st1" d="M502.2,504.2l-67.4-33.9l63.5-25.8l68,31.9l-0.6,2.5l5,2.5l-4.5,2l-0.8,27.1l-60.7,27.1
		l0.8-27.4l1.7-0.8l-5.6-2.5L502.2,504.2z"/>
      <path id="Loft-Wohnung-04" class="st1" d="M502.2,504.2l-67.4-33.9l-68,27.7l66.9,36.7l-0.6,2.5l4.8,2.8l-4.8,2.5l-0.8,28.5
		l72.5-33.3l0.8-27.4l1.7-0.8l-5.6-2.5L502.2,504.2z"/>
      <path id="Loft-Wohnung-03" class="st1" d="M358,604.9L292.3,564l-0.3-28l-4.5-2.8l5.6-4.2l74.4-30.2l66,35.8l4.2,5.3l-4.8,2.5
		l-0.8,28.5L358,604.9"/>
      <path id="Loft-Wohnung-02" class="st1" d="M287.5,533.3l5.6-4.2l74.4-30.2l-57.9-32.2l-75,28.5l-4.5,3.9l4.6,2.6l0.7,27l56.8,35.3
		l-0.3-28L287.5,533.3z"/>
      <path id="Loft-Wohnung-01" class="st1" d="M177.3,466.9l5-3.4l73.6-26.6l53.7,29.7l-75,28.5l-4.5,3.9l4.6,2.6l0.7,27L182.3,496
		l-0.8-26.6L177.3,466.9z"/>
      <path id="Atelier-Loft-03" class="st2" d="M405.9,432.7V431l35.5-14.3l62.4-21.8l32.5,14l-4.5,2.5l-57.9,22.4l-35.5,14.3l-10.9-5.6
		v-2l-16-7l-1.7,1.1L405.9,432.7z"/>
      <path id="Atelier-Loft-02" class="st2" d="M405.6,432.4l0.3-1.4l35.5-14.3l62.4-21.8l-29.1-12.6l-3.9,1.7l-57.9,19.9l-35,13.4
		l-0.3,1.4l2.8,1.4l2-1.6l16,8l-0.4,1.7L405.6,432.4z"/>
      <path id="Atelier-Loft-01" class="st2" d="M350.2,405.5l0.3-1.4l34.7-12.9l0.3-0.8l55.7-18.5h3.4l3.1-1.4l27.1,11.8l-3.9,1.7
		L413,403.9l-35,13.4l-0.3,1.4l-7.8-3.7l0.6-1.5l-14-7l-0.3,1.9L350.2,405.5z"/>
      <path id="Gewerbe-03" class="st3" d="M391,346.7l-38.6-16.8l-34.7,11.2l30.5,12.3l8.4,4.5L391,346.7z"/>
      <path id="Gewerbe-02" class="st3" d="M328.6,366.8l-9-4.8L290,349.7l27.7-8.7l30.5,12.3l8.4,4.5L328.6,366.8z"/>
      <path id="Gewerbe-01" class="st3" d="M328.6,366.8l-9-4.8L290,349.7l-32.5,10.1l3.1,2.6l3.3-0.9v3.9l1.7,1.1l0.3,13.1l9.5,5
		l21.5-7.3L328.6,366.8z"/>
      <path id="Patio-Haus-05" class="st4" d="M121.7,457l-0.6-16.8l-2.5-1.7l4.5-1.4V436l53.7-19.9l-0.6-1.7l16.4-5.6l0.1-16.3l41-13
		l8,4l22-7l13,7l-61.8,20l-13.7,6.2l-29.5,9.9l-31,11l0.9,21.1l-0.3,1.1l0.8,16.8L121.7,457z"/>
      <path id="Patio-Haus-04" class="st4" d="M121.7,457l-0.6-16.8l-2.5-1.7l4.5-1.4V436l53.7-19.9l-0.6-1.7l16.4-5.6l0.1-16.3l41-13
		l8,4l22-7l-32.2-16.1l-61.8,20.1l0.2,15.7l-16.1,5.6v1.1l-55.4,17.9v1.4l-3.6,1.7l3.6,2.2l0.8,17.4L121.7,457z"/>
      <path id="Patio-Haus-03" class="st4" d="M78.6,430.4L78,413.6l-3.9-2l4.5-1.4v-1.1l53.7-18.2l-0.3-1.4l16.4-5.6l0.3-15.4l40-12l7,3
		l22-6l14,7l-61,20l0.2,15.7l-17.1,5.6v1.1l-55.4,17.9v1.4l-3.6,1.7l3.6,2.2l0.8,17.4L78.6,430.4z"/>
      <path id="Patio-Haus-02" class="st4" d="M78.6,430.4L78,413.6l-3.9-2l4.5-1.4v-1.1l53.7-18.2l-0.3-1.4l16.4-5.6l0.3-15.4l40-12l7,3
		l21.8-6.1l-28.8-14.9l-61,17.9l0.4,16l-16.8,5.3v1.1l-1.7,0.6l-1.7-0.6l-50.7,16.8v1.4l-3.6,1.7l3.4,2l1.4,17.6L78.6,430.4z"/>
      <path id="Patio-Haus-01" class="st4" d="M38.6,406.1L37.8,389l-3.6-2l-0.3-0.8l3.9-1.4l0.8,0.3v-0.8l18.9-6l3.8,2.4l18.8-6.2v-2.8
		L86,370v-0.8l5.3-2v-0.8l13.2-3.9l-0.8-9.2l62.1-18.2l2.8,1.7l3.3-1.1l9.8,4.9l-53.8,16.1l0.4,15.9l-16.8,5.3v1.1l-1.7,0.6
		l-1.7-0.6l-50.7,16.8v1.4l-3.6,1.7l3.4,2l0.8,16.8l-5.9-4.2l-1.1,0.4L38.6,406.1z"/>
</g>
    <g id="level-2">
	<path id="Etagen-Wohnung-05" class="st5" d="M627.4,431.7l1.1-0.3l0.3-0.8l-70.5-29.4l-60.5,23.2l70.2,31.9l-1.1,20.7l59.3-26
		L627.4,431.7z"/>
      <path id="Etagen-Wohnung-04" class="st5" d="M502.8,483.4l-68.3-33.9
		l63.2-25.2l70.2,31.9l-1.1,20.7L502,505.5L502.8,483.4z"/>
      <path id="Etagen-Wohnung-03" class="st5" d="M502.8,483.4l-68.3-33.9
		l-68.3,26.9l67.2,36.1l-0.8,23.5l69.4-30.5L502.8,483.4z"/>
      <path id="Etagen-Wohnung-02" class="st5" d="M292.1,529.3l-0.3-23.5
		l74.4-29.4l67.2,36.1l-0.8,23.5l-74.4,32.2L292.1,529.3z"/>
      <path id="Etagen-Wohnung-01" class="st5" d="M292.1,529.3l-0.3-23.5
		l74.4-29.4l-61-32.7L230,470.9v1.1l1.4,0.8L232,493L292.1,529.3z"/>
</g>
    <g id="level-3">
	<path id="Etagen-Wohnung-013" class="st5" d="M346,369.4l98.5-33.2l0.6-22.1l0.6-0.8l-44.5-18.5c-1.3,0.5-4.2,1.4-5.6,1.4
		c-1.4,1-7.5,2.8-9.2,3.4h-2.2l-9.2,3.8l-5.6-0.2l-6.2,2.9l-10.6,4l-6.1,0.2l-5.5,3.5l-6.3,0.5l-5.2,3.1l-6,0.5l-6.4,3.4l-6.9,0.2
		l-6.9,4.1l42.8,19.9L346,369.4L346,369.4z"/>
      <path id="Etagen-Wohnung-014" class="st5" d="M293,386.8l53-17.4v-23.7c-2.2-1.9-41.1-18.2-43.4-20.3c-1.3,0.4-2,1.8-3.3,1.8
		l-4.2,0.4l-8.1,2.5l-3.1,0.3l-1.7,1.1c-2.1,0.6-9,3-11.2,2.8c-1.6,1.3-8.6,3.3-10.6,3.9l-3.1,0.3l-2.8,1.7l-4.8,1.4l41.7,20.6
		l1.1,0.3L293,386.8z"/>
      <path id="Etagen-Wohnung-015" class="st5" d="M293,386.8l-55.6,19l-0.7-24.8l-0.6-0.6l-0.8,0.3l-40.9-21.1l-0.3-0.8
		c1.7-0.6,6.1-2.2,7.6-3.1l3.4-0.3l8.4-2.8l2.8-1.4l3.4-0.3l7.8-2.5l2.8-1.4l3.1-0.3c2.1-0.7,8.9-2.7,10.6-3.9
		c1.5,0.3,4.5-0.6,5.9-1.1l41.7,20.6l1.1,0.3L293,386.8z"/>
      <path id="Etagen-Wohnung-09" class="st5" d="M567.4,453.7l1.1-23.2L498,399.4l60.2-23l71.4,29.1v0.8l-1.4,0.6l-1.4,21L567.4,453.7z
		"/>
      <path id="Etagen-Wohnung-08" class="st5" d="M567.4,453.7l1.1-23.2L498,399.4l-67.4,25.8v0.8l1.1,0.3l-0.3,21.8l10.6,5.8v2.2
		l1.4,0.3l1.7-0.8l54.4,27.1L567.4,453.7z"/>
      <path id="Etagen-Wohnung-07" class="st5" d="M434.9,509.9l-77.7,32.7l-66.3-38.3l-0.3-24.4l74.5-31.3l70.8,36.5l-0.3,0.8l-1.1,0.6
		L434.9,509.9z"/>
      <path id="Etagen-Wohnung-06" class="st5" d="M228.6,446l0.6,22.8l61.7,35.5l-0.3-24.4l74.5-31.3l-59.5-31.2l-78.4,26.9l-0.3,0.8
		L228.6,446z"/>
      <path id="Maisonette-01" class="st6" d="M176.2,450.2l59.6-20.4l0.6-46.4l-0.9-1.1l-1.7-0.8l-37.5-20.1l-4.4-0.1
		c-1.6,0.6-4.8,1.4-6.4,1.4c-3.1,1.9-10.9,4.2-14.6,4.5l-0.3,0.8l-13.7,3.9l-0.8-0.6l-2.2,1.7c-1.4,0.3-10.5,3.3-11.8,2.8
		c-1.7,1.6-4.4,2.7-6.6,3.2l0.5,48L176.2,450.2z"/>
      <path id="Etagen-Wohnung-012" class="st5" d="M292.4,382.9l0.8,0.2l0.9,3.9l-0.5,21.2l-51.5,19c-0.1,0-4.7,0.1-4.7-0.1l0-25.1
		l-39.5-21.2l55.8-17.5L292.4,382.9z"/>
      <path id="Etagen-Wohnung-011" class="st5" d="M346.6,365.1l0.2,3.4l-0.3,4.5l-0.4,17.3L294,408.1c-0.3,0.1-0.5,0.3-0.5,0.1
		l0.7-24.3l-39.3-21l49.9-16.3L346.6,365.1z"/>
      <path id="Etagen-Wohnung-010" class="st5" d="M445.1,331.8l1.1,3.2V353l-0.6,2.6l-98.8,33.7c-0.1,0,0-11.5,0-11.7l-0.2-12.5
		l-41.8-18.6l97.6-31.3L445.1,331.8z"/>
</g>
  </svg>
</template>

<script setup>
import { watch, ref } from 'vue'

const props = defineProps({
  selected: {
    type: String,
    required: true
  },
  number: {
    type: Number,
    required: true
  }
})

const st2 = ref([])

const highlightHause = (idContains, number) => {
  let elements = document.querySelectorAll(`[id*=${idContains}]`)

  // Iterate over the elements
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].id === idContains + number) {
      elements[i].style.display = 'inline'
    } else {
      elements[i].style.display = 'none'
    }
    // Do something with the element
  }
}

let mapper = {
  'atelier-loft': 'Atelier-Loft-0',
  loftwohnung: 'Loft-Wohnung-0',
  patiohaus: 'Patio-Haus-0',
  etagenwohnung: 'Etagen-Wohnung-0',
  'maisonette-wohnung': 'Maisonette-0',
  gewerbe: 'Gewerbe-0'
}

// Watch for changes in the 'selected' prop
watch(props, (newProps, oldProps) => {
  highlightHause(mapper[newProps.selected], newProps.number)

  if (
    (newProps.selected === 'gewerbe' ||
      newProps.selected === 'atelier-loft' ||
      newProps.selected === 'loftwohnung' ||
      newProps.selected === 'patiohaus') &&
    newProps.number !== null
  ) {
    document.querySelector('.level-2').classList.remove('show-alt')
    document.querySelector('.level-2').classList.add('hide')
    document.querySelector('.level-3-4').classList.add('hide')
    document.querySelector('.level-3-2').classList.add('hide')
    document.querySelector('.level-3-1').classList.add('hide')
    document.querySelector('.level-3-3').classList.add('hide')
    document.querySelector('.level-3-5').classList.add('hide')
    document.querySelector('.level-3-1').classList.remove('show-alt')
    document.querySelector('.level-3-4').classList.remove('show-alt')
    document.querySelector('.level-3-2').classList.remove('show-alt')
    document.querySelector('.level-3-3').classList.remove('show-alt')
    document.querySelector('.level-3-5').classList.remove('show-alt')
  } else if (
    (newProps.selected === 'gewerbe' ||
      newProps.selected === 'atelier-loft' ||
      newProps.selected === 'loftwohnung' ||
      newProps.selected === 'patiohaus') &&
    newProps.number == null
  ) {
    document.querySelector('.level-2').classList.add('show-alt')
    document.querySelector('.level-2').classList.remove('hide')
    document.querySelector('.level-3-4').classList.remove('hide')
    document.querySelector('.level-3-2').classList.remove('hide')
    document.querySelector('.level-3-1').classList.remove('hide')
    document.querySelector('.level-3-3').classList.remove('hide')
    document.querySelector('.level-3-5').classList.remove('hide')
    document.querySelector('.level-3-1').classList.add('show-alt')
    document.querySelector('.level-3-4').classList.add('show-alt')
    document.querySelector('.level-3-2').classList.add('show-alt')
    document.querySelector('.level-3-3').classList.add('show-alt')
    document.querySelector('.level-3-5').classList.add('show-alt')
  }
  if (newProps.selected === 'etagenwohnung' && (newProps.number <= 5 || (newProps.number > 9 && newProps.number < 13)) && newProps.number !== null){
    document.querySelector('.level-3-4').classList.add('hide')
    document.querySelector('.level-3-2').classList.add('hide')
    document.querySelector('.level-3-1').classList.add('hide')
    document.querySelector('.level-3-1').classList.remove('show-alt')
    document.querySelector('.level-3-4').classList.remove('show-alt')
    document.querySelector('.level-3-2').classList.remove('show-alt')
  }
  else if (newProps.selected === 'etagenwohnung' && (newProps.number > 5 || (newProps.number < 9 && newProps.number > 13)) || newProps.number == null){
    document.querySelector('.level-3-4').classList.add('show-alt')
    document.querySelector('.level-3-2').classList.add('show-alt')
    document.querySelector('.level-3-1').classList.add('show-alt')
    document.querySelector('.level-3-1').classList.remove('hide')
    document.querySelector('.level-3-4').classList.remove('hide')
    document.querySelector('.level-3-2').classList.remove('hide')
  }
})
</script>

<style scoped>
.st0 {
  display: none;
}
.st1 {
  display: none;
  //display: inline;
  opacity: 0.7;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ce843c;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st2 {
  display: none;
  //display: inline;
  opacity: 0.7;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #9c52b5;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st3 {
  display: none;
  //display: inline;
  opacity: 0.7;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #52b259;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st4 {
  display: none;
  //display: inline;
  opacity: 0.7;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ddd54a;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st5 {
  display: none;
  //display: inline;
  opacity: 0.7;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #b65073;
  stroke: #000000;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st6 {
  display: none;
  //display: inline;
  opacity: 0.7;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #498fa6;
  stroke: #000000;
  stroke-miterlimit: 10;
  enable-background: new;
}

.level-3-2 {
  position: relative;
  transform: matrix(1, 0, 0, 1, 431, 0);
  opacity: 0;
  //display: none;
}

.level-3-2.show {
  animation: level-three-two-animation 1s forwards;
  animation-delay: 2.5s;
}
.level-3-2.show-alt {
  animation: level-three-two-animation-alt 1s forwards;
}
.level-3-2.hide {
  animation: level-three-two-animation-hide 1s forwards;
}

.level-3-1.show {
  animation: level-three-one-animation 1s forwards;
  animation-delay: 2.5s;
}

.level-3-1 {
  position: relative;
  transform: matrix(1, 0, 0, 1, 228, 0);
  opacity: 0;
}
.level-3-4 {
  position: relative;
  transform: matrix(1, 0, 0, 1, 196, 0);
  opacity: 0;
}
.level-3-5 {
  position: relative;
  transform: matrix(1, 0, 0, 1, 131, 0);
  opacity: 0;
}
.level-3-1.show-alt {
  animation: level-three-one-animation-alt 1s forwards;
}
.level-3-1.hide {
  animation: level-three-one-animation-hide 1s forwards;
}

.level-3-3.show {
  animation: level-three-three-animation 1s forwards;
  animation-delay: 2s;
}
.level-3-3.show-alt {
  animation: level-three-three-animation-alt 1s forwards;
}
.level-3-3.hide {
  animation: level-three-three-animation-hide 1s forwards;
}

.level-3-4.show {
  animation: level-three-four 1s forwards;
  animation-delay: 2.5s;
}
.level-3-4.show-alt {
  animation: level-three-four-alt 1s forwards;
}
.level-3-4.hide {
  animation: level-three-four-hide 1s forwards;
}

.level-3-5.show {
  animation: level-three-five 1s forwards;
  animation-delay: 2.5s;
}
.level-3-5.show-alt {
  animation: level-three-five-alt 1s forwards;
}
.level-3-5.hide {
  animation: level-three-five-hide 1s forwards;
}

.level-3-3 {
  position: relative;
  transform: matrix(1, 0, 0, 1, 130, 0);
  opacity: 0;
}

.level-2 {
  transform: matrix(1, 0, 0, 1, 229, 0);
  position: relative;
  opacity: 0;
}
.level-2.show {
  animation: level-two 1s forwards;
  animation-delay: 2s;
}
.level-2.show-alt {
  animation: level-two-alt 1s forwards;
  //animation-delay: 2s;
}
.level-2.hide {
  animation: level-two-hide 1s forwards;
}

.level-1.hide {
  animation: level-one-hide 1s forwards;
}
.level-1 {
  position: relative;
  transform: matrix(1, 0, 0, 1, 2, 0);
  animation: level-one 1s forwards;
  animation-delay: 1.5s;
  opacity: 0;
}

@keyframes level-three-two-animation {
  0% {
    transform: matrix(1, 0, 0, 1, 431, 0);
    opacity: 0;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 431, 377);
    opacity: 1;
  }
}

@keyframes level-three-two-animation-alt {
  0% {
    transform: matrix(1, 0, 0, 1, 431, 200);
    opacity: 0.28;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 431, 377);
    opacity: 1;
  }
}

@keyframes level-three-one-animation {
  0% {
    transform: matrix(1, 0, 0, 1, 228, 0);
    opacity: 0;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 228, 419);
    opacity: 1;
  }
}
@keyframes level-three-one-animation-alt {
  0% {
    transform: matrix(1, 0, 0, 1, 228, 250);
    opacity: 0.28;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 228, 419);
    opacity: 1;
  }
}

@keyframes level-three-three-animation {
  0% {
    transform: matrix(1, 0, 0, 1, 195, 0);
    opacity: 0;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 195, 317);
    opacity: 1;
  }
}
@keyframes level-three-three-animation-alt {
  0% {
    transform: matrix(1, 0, 0, 1, 195, 175);
    opacity: 0.28;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 195, 317);
    opacity: 1;
  }
}

@keyframes level-three-two-animation-hide {
  0% {
    transform: matrix(1, 0, 0, 1, 431, 377);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 431, 200);
    opacity: 0.28;
  }
}

@keyframes level-three-one-animation-hide {
  0% {
    transform: matrix(1, 0, 0, 1, 228, 419);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 228, 250);
    opacity: 0.28;
  }
}

@keyframes level-three-three-animation-hide {
  0% {
    transform: matrix(1, 0, 0, 1, 195, 317);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 195, 175);
    opacity: 0.28;
  }
}

@keyframes level-two {
  0% {
    transform: matrix(1, 0, 0, 1, 229, 0);
    opacity: 0;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 229, 399);
    opacity: 1;
  }
}
@keyframes level-two-alt {
  0% {
    transform: matrix(1, 0, 0, 1, 229, 250);
    opacity: 0.28;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 229, 399);
    opacity: 1;
  }
}

@keyframes level-two-hide {
  0% {
    transform: matrix(1, 0, 0, 1, 229, 399);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 229, 250);
    opacity: 0.28;
  }
}

@keyframes level-one {
  0% {
    transform: matrix(1, 0, 0, 1, 2, 0);
    opacity: 0;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 2, 329);
    opacity: 1;
  }
}

@keyframes level-one-hide {
  0% {
    transform: matrix(1, 0, 0, 1, 2, 329);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 2, 0);
    opacity: 1;
  }
}

@keyframes level-three-four {
  0% {
    transform: matrix(1, 0, 0, 1, 196, 0);
    opacity: 0;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 196, 297);
    opacity: 1;
  }
}
@keyframes level-three-four-alt {
  0% {
    transform: matrix(1, 0, 0, 1, 196, 157);
    opacity: 0.28;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 196, 297);
    opacity: 1;
  }
}

@keyframes level-three-four-hide {
  0% {
    transform: matrix(1, 0, 0, 1, 196, 297);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 196, 157);
    opacity: 0.28;
  }
}

@keyframes level-three-five {
  0% {
    transform: matrix(1, 0, 0, 1, 131, 0);
    opacity: 0;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 131, 361);
    opacity: 1;
  }
}
@keyframes level-three-five-alt {
  0% {
    transform: matrix(1, 0, 0, 1, 131, 218);
    opacity: 0.28;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 131, 361);
    opacity: 1;
  }
}

@keyframes level-three-five-hide {
  0% {
    transform: matrix(1, 0, 0, 1, 131, 361);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 131, 218);
    opacity: 0.28;
  }
}
</style>
