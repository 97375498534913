<template>
  <svg width="143" height="32" viewBox="0 0 143 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1064_4562)">
    <path d="M33.3792 14.3435H22.8047V20.6682H33.3792V14.3435Z" fill="#B1063B"/>
    <path d="M61.0007 14.3811H63.2963C64.8768 14.3811 65.1778 15.5105 65.1778 16.3011C65.1778 17.4305 64.651 18.2211 63.1457 18.2211H62.4684V20.7058H61.0007V14.3811ZM62.4684 17.1293H62.8823C63.4844 17.1293 63.6349 16.7529 63.6349 16.3388C63.6349 15.8493 63.4844 15.4729 62.8823 15.4729H62.506V17.1293H62.4684Z" fill="#12120D"/>
    <path d="M67.1724 14.3811H65.7424V20.7058H67.1724V14.3811Z" fill="#12120D"/>
    <path d="M67.8122 17.3929C67.8122 16.7529 68.0003 16.3388 68.339 16.1129C68.6777 15.8871 69.0917 15.8118 69.6561 15.8118C70.8227 15.8118 71.3496 16.2259 71.3496 17.2424V19.6894C71.3496 20.0659 71.3496 20.5177 71.5001 20.7059H70.1077C70.0701 20.5553 70.0324 20.3294 70.0324 20.1412C69.8443 20.5553 69.3927 20.8188 68.9035 20.8188C68.1132 20.8188 67.624 20.4047 67.624 19.3506C67.624 18.7859 67.8498 18.3341 68.2261 18.1459C68.5648 17.9577 69.1293 17.8447 69.5056 17.7694C69.9572 17.6565 70.0324 17.5812 70.0324 17.2047C70.0324 16.9035 69.8819 16.7153 69.5809 16.7153C69.1669 16.7153 69.054 16.9412 69.054 17.3553H67.8122V17.3929ZM69.468 19.84C69.769 19.84 70.0324 19.6518 70.0324 19.2C70.0324 18.7859 70.0324 18.56 70.0324 18.3718C69.5809 18.5976 69.3927 18.6353 69.2422 18.7106C69.0917 18.8235 69.0164 18.9741 69.0164 19.2376C69.054 19.6141 69.2046 19.84 69.468 19.84Z" fill="#12120D"/>
    <path d="M72.1396 15.9247H73.532V16.4894C73.7202 16.0753 74.1341 15.8118 74.6986 15.8118C75.5265 15.8118 75.9404 16.2259 75.9404 17.1294V20.7059H74.5104V17.4306C74.5104 17.0918 74.3599 16.8659 74.0965 16.8659C73.8331 16.8659 73.5696 17.0918 73.5696 17.5435V20.7059H72.1396V15.9247Z" fill="#12120D"/>
    <path d="M76.806 16.9035H76.2039V15.9247H76.806V14.5317H78.236V15.9247H78.9133V16.9035H78.236V19.3129C78.236 19.6517 78.3489 19.7647 78.6875 19.7647C78.7628 19.7647 78.8381 19.7647 78.8757 19.7647V20.7059C78.6123 20.7435 78.3112 20.7435 78.0478 20.7435C77.0694 20.7435 76.7683 20.5553 76.7683 19.4635V16.9035H76.806Z" fill="#12120D"/>
    <path d="M85.1602 20.706H83.7678V20.1413C83.5796 20.5554 83.1657 20.8189 82.6012 20.8189C81.7733 20.8189 81.3594 20.4048 81.3594 19.5013V15.9248H82.7894V19.2001C82.7894 19.5389 82.9399 19.7648 83.2033 19.7648C83.4667 19.7648 83.7302 19.5389 83.7302 19.0872V15.9625H85.1602V20.706Z" fill="#12120D"/>
    <path d="M85.8376 15.9247H87.23V16.4894C87.4182 16.0753 87.8321 15.8118 88.3966 15.8118C89.2245 15.8118 89.6384 16.2259 89.6384 17.1294V20.7059H88.2084V17.4306C88.2084 17.0918 88.0579 16.8659 87.7945 16.8659C87.5311 16.8659 87.2676 17.0918 87.2676 17.5435V20.7059H85.8376V15.9247Z" fill="#12120D"/>
    <path d="M92.7242 20.1035C92.6113 20.4423 92.3103 20.8188 91.6705 20.8188C90.6921 20.8188 90.2029 20.1412 90.2029 18.3341C90.2029 16.8282 90.4287 15.7741 91.5952 15.7741C92.0092 15.7741 92.4231 15.9247 92.6489 16.4141V14.3435H94.0789V20.7059H92.6866V20.1035H92.7242ZM92.1597 16.8282C91.6329 16.8282 91.5952 17.3929 91.5952 18.2588C91.5952 19.0117 91.5952 19.7647 92.1597 19.7647C92.5737 19.7647 92.6866 19.2376 92.6866 18.3341C92.6866 17.4682 92.6489 16.8282 92.1597 16.8282Z" fill="#12120D"/>
    <path d="M96.9014 14.3811H98.3314V16.4517C98.5572 15.9246 99.0087 15.8117 99.3851 15.8117C100.552 15.8117 100.777 16.8658 100.777 18.3717C100.777 20.1411 100.251 20.8564 99.3098 20.8564C98.67 20.8564 98.369 20.4799 98.2937 20.1411V20.7058H96.9014V14.3811ZM98.8206 19.7646C99.3474 19.7646 99.3851 19.0493 99.3851 18.2588C99.3851 17.3929 99.3474 16.8282 98.8206 16.8282C98.3314 16.8282 98.2937 17.4682 98.2937 18.334C98.3314 19.2752 98.4066 19.7646 98.8206 19.7646Z" fill="#12120D"/>
    <path d="M101.342 17.3929C101.342 16.7529 101.53 16.3388 101.869 16.1129C102.207 15.8871 102.621 15.8118 103.186 15.8118C104.352 15.8118 104.879 16.2259 104.879 17.2424V19.6894C104.879 20.0659 104.879 20.5177 105.03 20.7059H103.637C103.6 20.5553 103.562 20.3294 103.562 20.1412C103.374 20.5553 102.922 20.8188 102.433 20.8188C101.643 20.8188 101.154 20.4047 101.154 19.3506C101.154 18.7859 101.379 18.3341 101.718 18.1459C102.057 17.9577 102.621 17.8447 102.998 17.7694C103.449 17.6565 103.524 17.5812 103.524 17.2047C103.524 16.9035 103.374 16.7153 103.073 16.7153C102.659 16.7153 102.546 16.9412 102.546 17.3553H101.342V17.3929ZM102.998 19.84C103.299 19.84 103.562 19.6518 103.562 19.2C103.562 18.7859 103.562 18.56 103.562 18.3718C103.11 18.5976 102.922 18.6353 102.772 18.7106C102.621 18.8235 102.546 18.9741 102.546 19.2376C102.584 19.6141 102.734 19.84 102.998 19.84Z" fill="#12120D"/>
    <path d="M109.47 20.706H108.078V20.1413C107.89 20.5554 107.476 20.8189 106.911 20.8189C106.083 20.8189 105.669 20.4048 105.669 19.5013V15.9248H107.099V19.2001C107.099 19.5389 107.25 19.7648 107.513 19.7648C107.777 19.7648 108.04 19.5389 108.04 19.0872V15.9625H109.47V20.706Z" fill="#12120D"/>
    <path d="M110.298 16.9035H109.696V15.9247H110.298V14.5317H111.728V15.9247H112.406V16.9035H111.728V19.3129C111.728 19.6517 111.841 19.7647 112.18 19.7647C112.255 19.7647 112.33 19.7647 112.368 19.7647V20.7059C112.104 20.7435 111.803 20.7435 111.54 20.7435C110.562 20.7435 110.261 20.5553 110.261 19.4635V16.9035H110.298Z" fill="#12120D"/>
    <path d="M114.287 19.0872H112.895V20.6683H114.287V19.0872Z" fill="#12120D"/>
    <path d="M116.846 14.3811H120.534V15.5858H118.351V16.8658H120.383V18.0329H118.351V20.7058H116.846V14.3811Z" fill="#12120D"/>
    <path d="M124.711 20.7058H123.319V20.1411C123.13 20.5552 122.716 20.8188 122.152 20.8188C121.324 20.8188 120.91 20.4046 120.91 19.5011V15.9246H122.34V19.1999C122.34 19.5388 122.491 19.7646 122.754 19.7646C123.018 19.7646 123.281 19.5388 123.281 19.087V15.9623H124.711V20.7058ZM121.512 14.1929H122.604V15.3599H121.512V14.1929ZM123.055 14.1929H124.146V15.3599H123.055V14.1929Z" fill="#12120D"/>
    <path d="M125.388 15.9248H126.781V16.5648C126.969 16.1506 127.345 15.7742 127.947 15.7742C128.023 15.7742 128.098 15.7742 128.173 15.7742V17.1295C128.023 17.0918 127.91 17.0918 127.759 17.0918C127.195 17.0918 126.818 17.3177 126.818 17.9577V20.7059H125.388V15.9248Z" fill="#12120D"/>
    <path d="M131.974 18.7481V18.974C131.974 19.4634 132.124 19.8022 132.614 19.8022C133.14 19.8022 133.291 19.4258 133.291 19.0493C133.291 17.694 130.582 18.4469 130.582 16.0752C130.582 15.0587 131.108 14.2305 132.651 14.2305C134.157 14.2305 134.683 14.9834 134.683 15.9622V16.1128H133.253C133.253 15.8116 133.216 15.5858 133.14 15.4352C133.065 15.2846 132.915 15.2093 132.689 15.2093C132.313 15.2093 132.087 15.4352 132.087 15.8869C132.087 17.2046 134.796 16.5269 134.796 18.7858C134.796 20.2164 134.006 20.7811 132.651 20.7811C131.56 20.7811 130.582 20.4422 130.582 19.1246V18.7105H131.974V18.7481Z" fill="#12120D"/>
    <path d="M135.323 14.3059H136.753V15.3977H135.323V14.3059ZM135.323 15.9247H136.753V20.7059H135.323V15.9247Z" fill="#12120D"/>
    <path d="M138.748 18.5224V18.8235C138.748 19.5765 138.861 19.9153 139.274 19.9153C139.651 19.9153 139.764 19.6518 139.801 19.0494H141.118C141.081 20.2165 140.441 20.8188 139.274 20.8188C137.468 20.8188 137.318 19.5388 137.318 18.1459C137.318 16.7906 137.694 15.8118 139.274 15.8118C141.043 15.8118 141.194 16.9412 141.194 18.56H138.748V18.5224ZM139.764 17.6941C139.764 17.0165 139.688 16.6776 139.274 16.6776C138.823 16.6776 138.71 17.0541 138.71 17.6941H139.764Z" fill="#12120D"/>
    <path d="M143 19.0872H141.608V20.6683H143V19.0872Z" fill="#12120D"/>
    <path d="M47.4912 20.7059H35.6372V14.3812L47.4912 0H54.8293V14.3812H58.3291V20.7059H54.8293V25.8259H47.4912V20.7059ZM41.3572 14.3812H47.4912V7.15294L41.3572 14.3812Z" fill="#12120D"/>
    <path d="M0 6.51285H7.07474V8.62108H7.15C8.09079 7.41638 9.18211 6.02344 12.7947 6.02344C18.2513 6.02344 20.8855 11.407 20.8855 16.2258C20.8855 20.7434 19.1545 26.2399 12.6818 26.2399C10.4992 26.2399 8.54237 25.2611 7.41342 23.6423H7.33816V31.9999H0V6.51285ZM10.3111 20.894C12.9453 20.894 13.5474 18.4093 13.5474 16.1505C13.5474 13.854 12.9453 11.3693 10.3111 11.3693C7.67684 11.3693 7.07474 13.854 7.07474 16.1505C7.07474 18.4093 7.67684 20.894 10.3111 20.894Z" fill="#12120D"/>
    </g>
    <defs>
    <clipPath id="clip0_1064_4562">
    <rect width="143" height="32" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>
