<script setup>
import { RouterLink } from 'vue-router'
import LogoGold from './assets/LogoGold.vue'
import LogoSmall from './assets/LogoSmall.vue'
import RightArrow from './../components/assets/RightArrow.vue'
import CloseIcon from './../components/assets/CloseIcon.vue'
import axios from 'axios'
import { ref, reactive, onMounted } from 'vue'

const baseURL = import.meta.env.VITE_URL

const state = reactive([])

const getContent = () => {
  axios.get(`${baseURL}/api/navbars`).then(async (res) => {
    // console.log(res.data.data)
    state.value = await res.data.data
  })
}

const navbarState = reactive({
  isScrolled: false
})

const navbar = ref([])
const handleScroll = () => {
  navbarState.isScrolled = window.scrollY > 200
  // console.log(navbar.value.offsetTop)
  // window.a = navbar.value
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})
onMounted(getContent)

let loading = ref(false)

const contentLoaded = ref(false)

onMounted(() => {
  // Simulating content loading delay
  setTimeout(() => {
    // Set contentLoaded to true after content is loaded
    contentLoaded.value = true
  }, 4000) // Adjust the delay as needed
})

const checkboxValidation = () => {
  let checkboxesContainer = document.getElementById('checkbox-container')
  let checkboxes = checkboxesContainer.querySelectorAll('input[type="checkbox"]')
  let isChecked = false

  checkboxes.forEach((checkbox) => {
    if (checkbox.checked) {
      isChecked = true
    }
  })

  checkboxes.forEach((checkbox) => {
    if (isChecked) {
      checkbox.removeAttribute('required')
    } else {
      checkbox.setAttribute('required', 'required')
    }
  })
}

const submitForm = () => {
  let forms = document.querySelectorAll('.needs-validation')

  Array.prototype.slice.call(forms).forEach(function (form) {
    const submitHandler = function (event) {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }

      form.classList.add('was-validated')

      if (form.checkValidity()) {
        event.preventDefault()
        console.log('valid')
        sendMessage(form)
        form.classList.remove('was-validated')
      }
    }

    form.addEventListener('submit', submitHandler, false)

    // Remove the event listener after submission
    form.addEventListener(
      'submit',
      function () {
        form.removeEventListener('submit', submitHandler)
      },
      { once: true }
    )
  })
}

const formData = reactive({
  data: {
    name: '',
    vorname: '',
    strasse: '',
    plz: '',
    email: '',
    bemerkung: '',
    anrede: '',
    interessiere: []
  }
})

const sendMessage = () => {
  formData.interessiere = JSON.stringify(formData.interessiere)

  loading.value = true
  axios
    .post(`${baseURL}/api/contact-forms`, formData)
    .then(() => {
      console.log(formData)
      clearInputs()
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      setTimeout(() => {
        loading.value = false
      }, 500)
    })
}

const clearInputs = () => {
  formData.data = {
    name: '',
    vorname: '',
    strasse: '',
    plz: '',
    email: '',
    bemerkung: '',
    anrede: '',
    interessiere: []
  }
}

const toggler = ref([])

const clickToggler = () => {
  if (window.innerWidth <= 991) {
    toggler.value.click()
  }
}
</script>

<template>
  <nav
    ref="navbar"
    :class="{
      scrolled: navbarState.isScrolled
    }"
    class="bg-white navbar sticky-top navbar-expand-lg py-1"
  >
    <div class="container">
      <router-link
        class="navbar-brand"
        aria-label="Rütschi+ Logo"
        v-if="!navbarState.isScrolled"
        to="/"
      >
        <logo-gold />
      </router-link>
      <router-link
        class="navbar-brand"
        aria-label="Rütschi+ Logo"
        v-if="navbarState.isScrolled"
        to="/"
        ><logo-small
      /></router-link>
      <button
        class="navbar-toggler ms-auto"
        type="button"
        ref="toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0 flex-grow-1 justify-content-end">
          <template v-if="state.value">
            <li
              @click="clickToggler()"
              class="nav-item"
              style="padding-left: 1rem; padding-right: 1rem"
            >
              <router-link class="nav-link px-0 pb-1" to="/lage">LAGE</router-link>
            </li>
            <li
              @click="clickToggler()"
              class="nav-item"
              style="padding-left: 1rem; padding-right: 1rem"
            >
              <router-link class="nav-link px-0 pb-1" to="/geschichte">GESCHICHTE</router-link>
            </li>
            <li
              @click="clickToggler()"
              class="nav-item"
              style="padding-left: 1rem; padding-right: 1rem"
            >
              <router-link class="nav-link px-0 pb-1" to="/angebot">ANGEBOT</router-link>
            </li>
            <li
              @click="clickToggler()"
              class="nav-item"
              style="padding-left: 1rem; padding-right: 1rem"
            >
              <router-link class="nav-link px-0 pb-1" to="/angebot#patiohaus">PREISE</router-link>
            </li>
            <li
              @click="clickToggler()"
              class="nav-item"
              style="padding-left: 1rem; padding-right: 1rem"
            >
              <router-link class="nav-link px-0 pb-1" to="/ausbau">AUSBAU</router-link>
            </li>
            <li
              @click="clickToggler()"
              class="nav-item"
              style="padding-left: 1rem; padding-right: 1rem"
            >
              <router-link class="nav-link px-0 pb-1" to="/together">TOGETHER</router-link>
            </li>
            <li
              @click="clickToggler()"
              class="nav-item"
              style="padding-left: 1rem; padding-right: 1rem"
            >
              <router-link class="nav-link px-0 pb-1" to="kontakt">KONTAKT</router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
  <div class="navbar-push" v-if="navbarState.isScrolled"></div>

  <button
      v-if="contentLoaded"
    class="btn-contact btn btn-secondary hover-blue position-fixed end-0 rounded-0"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasRight"
  >
    JETZT ANFRAGEN
  </button>

  <div
    v-if="contentLoaded"
    class="offcanvas offcanvas-end text-white"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <button
      class="btn-contact d-none d-md-inline-block btn btn-secondary hover-blue position-absolute start-0 rounded-0"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
    >
      JETZT ANFRAGEN
    </button>
    <div class="offcanvas-header bg-primary pt-0 pt-md-5 pb-0 px-4 px-md-5">
      <h2 class="display-6 fw-bold text-white font-helvetica-black ps-md-5 mt-5">
        Ich interessiere mich für: *
      </h2>
      <button
        type="button"
        class="btn btn-secondary hover-blue rounded-0 p-2 me-md-5"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <CloseIcon />
      </button>
    </div>
    <div class="offcanvas-body bg-primary px-md-5 pb-5">
      <div class="px-3 px-md-5 pb-5">
        <div class="d-flex justify-content-center mt-5 pt-5 mb-5 pb-5" v-if="loading">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <form
          class="mt-4 mt-md-5 needs-validation"
          v-show="!loading"
          novalidate
          @change="checkboxValidation"
        >
          <fieldset class="row">
            <div class="mb-5 col-md-8">
              <label
                for=""
                class="form-label d-block text-uppercase font-helvetica-black small mb-3"
                >art des objekts</label
              >

              <div class="input-group has-validation" id="checkbox-container">
                <div class="form-check form-check-inline col-md-3 mt-2 mb-3">
                  <input
                    required
                    v-model="formData.data.interessiere"
                    type="checkbox"
                    id="Patiohaus"
                    value="Patiohaus"
                  />
                  <label class="form-check-label" for="Patiohaus">Patiohaus</label>
                </div>
                <div class="form-check form-check-inline col-md-3 mt-2 mb-3">
                  <input
                    required
                    v-model="formData.data.interessiere"
                    type="checkbox"
                    id="Etagenwohnung"
                    value="Etagenwohnung"
                  />
                  <label class="form-check-label" for="Etagenwohnung">Etagenwohnung</label>
                </div>
                <div class="form-check form-check-inline col-md-3 mt-2 mb-3">
                  <input
                    required
                    v-model="formData.data.interessiere"
                    type="checkbox"
                    id="Maisonettewohnung"
                    value="Maisonettewohnung"
                  />
                  <label class="form-check-label" for="Maisonettewohnung">Maisonettewohnung</label>
                </div>
                <div class="form-check form-check-inline col-md-3 mt-2 mb-3">
                  <input
                    required
                    v-model="formData.data.interessiere"
                    type="checkbox"
                    id="Atelierhaus"
                    value="Atelierhaus"
                  />
                  <label class="form-check-label" for="Atelierhaus">Atelier-Loft</label>
                </div>
                <div class="form-check form-check-inline col-md-3 mt-2 mb-3">
                  <input
                    required
                    v-model="formData.data.interessiere"
                    type="checkbox"
                    id="Loftwohnung"
                    value="Loftwohnung"
                  />
                  <label class="form-check-label" for="Loftwohnung">Loftwohnung</label>
                </div>
                <div class="form-check form-check-inline col-md-3 mt-2 mb-3">
                  <input
                    required
                    v-model="formData.data.interessiere"
                    type="checkbox"
                    id="Gewerbe"
                    value="Gewerbe"
                  />
                  <label class="form-check-label" for="Gewerbe">Gewerbe</label>
                </div>
              </div>
            </div>
            <!--            <div class="mb-3 col-md-8">-->
            <!--              <label for="Mietzweck" class="form-label">Mietzweck *</label>-->
            <!--              <input-->
            <!--                type="text"-->
            <!--                required-->
            <!--                class="form-control"-->
            <!--                id="Mietzweck"-->
            <!--                v-model="formData.data.mietzweck"-->
            <!--              />-->
            <!--            </div>-->
            <label
              for=""
              class="mt-2 form-label d-block text-uppercase font-helvetica-black small mb-3"
              >persönliche angaben</label
            >
            <div class="input-group has-validation d-flex mb-5">
              <div class="form-check form-check-inline">
                <input
                  required
                  v-model="formData.data.anrede"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="Frau"
                />
                <label class="form-check-label" for="inlineRadio1">Frau</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  required
                  v-model="formData.data.anrede"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="Herr"
                />
                <label class="form-check-label" for="inlineRadio2">Herr</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  required
                  v-model="formData.data.anrede"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="Divers"
                />
                <label class="form-check-label" for="inlineRadio3">Divers</label>
              </div>
            </div>

            <div class="mb-4 col-md-6">
              <label for="Vorname" class="form-label">Vorname *</label>
              <input
                type="text"
                class="form-control"
                required
                id="Vorname"
                v-model="formData.data.vorname"
              />
            </div>

            <div class="mb-4 col-md-6">
              <label for="Name" class="form-label">Name *</label>
              <input
                type="text"
                class="form-control"
                required
                id="Name"
                v-model="formData.data.name"
              />
            </div>

            <div class="mb-4 col-md-6">
              <label for="Strasse" class="form-label">Strasse *</label>
              <input
                type="text"
                class="form-control"
                required
                id="Strasse"
                v-model="formData.data.strasse"
              />
            </div>

            <div class="mb-4 col-md-6">
              <label for="PLZ" class="form-label">PLZ Ort *</label>
              <input
                type="text"
                class="form-control"
                required
                id="PLZ"
                v-model="formData.data.plz"
              />
            </div>

            <div class="mb-4 col-md-6">
              <label for="email" class="form-label">E-Mail *</label>
              <input
                type="email"
                class="form-control"
                required
                id="email"
                v-model="formData.data.email"
              />
            </div>
            <div class="mb-4 col-md-6">
              <label for="Telefon" class="form-label">Telefonnummer *</label>
              <input
                type="text"
                class="form-control"
                required
                id="Telefon"
                v-model="formData.data.telefon"
              />
            </div>

            <div class="mb-4 col-md-12">
              <label for="bemerkung" class="form-label">Fragen und Bemerkungen</label>
              <textarea
                class="form-control"
                v-model="formData.data.bemerkung"
                id="bemerkung"
              ></textarea>
            </div>

            <div class="col-md-auto ms-auto">
              <div class="btn-group rounded-0 mt-5 border border-1 border-secondary" role="group">
                <button
                  type="submit"
                  @click="submitForm"
                  class="btn btn-secondary hover-blue rounded-0 border-0"
                >
                  Anfrage senden
                </button>
                <button
                  type="button"
                  class="btn btn-primary rounded-0 border-0"
                  aria-label="Right arrow"
                >
                  <RightArrow style="scale: 0.7" />
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-contact {
  white-space: nowrap;
  rotate: -90deg;
  z-index: 10;
  top: 50%;
  letter-spacing: 1px;
  transform: translate3d(calc(-50% + 108px), 77px, 0);
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-width: min(95vw, 1170px) !important;
}

.offcanvas {
  border: 0 !important;
  background-color: transparent;
}

.offcanvas-body {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
}
.offcanvas .form-control {
  background-color: transparent;
  border-radius: 2px;
  border-width: 2px;
}
.offcanvas .btn-contact {
  margin-left: -191px;
}
.offcanvas-backdrop.fade.show {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 992px) {
  .offcanvas::before,
  .offcanvas::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    pointer-events: none;
  }
  .offcanvas::after {
    left: 36px;
    top: 36px;
    bottom: 4.5%;
    border-top: 3px solid var(--bs-secondary);
    border-left: 3px solid var(--bs-secondary);
  }
  .offcanvas::before {
    left: 36px;
    bottom: 4.3%;
    right: 0;
    height: 0;
    transform-origin: left bottom;
    margin-top: 0;
    border-top: 3px solid var(--bs-secondary);
    rotate: 3deg;
  }

  .navbar {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 84%);
  }
  .nav-link {
    color: #11263d;
    letter-spacing: 1px;
    border-bottom: 3px solid transparent;
  }
  .nav-link.active,
  .nav-link:hover {
    border-color: #ccccb0;
  }
  .navbar-push,
  .navbar:not(.scrolled) {
    height: 200px;
    transition: height 0.2s ease-in;
  }
  .navbar.scrolled {
    height: 65px;
    background: #fff;
    transition: height 0.2s ease-in;
  }
}
.form-check-label,
.form-label {
  color: #fff;
}
.form-select,
.form-control {
  border-color: rgba(var(--bs-secondary-rgb), 0.45);
  border-radius: 2px !important;
  carret: var(--bs-secondary) !important;
  color: var(--bs-secondary) !important;
}

.form-check-inline {
  margin-right: 40px;
  margin-bottom: 16px;
}

.form-select:focus,
.form-control:focus {
  border-color: rgba(var(--bs-secondary-rgb), 0.8) !important;
  box-shadow: none !important;
}
.form-control::placeholder {
  color: var(--bs-secondary) !important;
}
</style>

<style>
.form-check-inline > input:not(:checked) {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
}
.form-check-inline > input {
  margin-right: 7px;
  border: 2px solid var(--bs-secondary);
  float: left;
  margin-left: -1.5em;
  margin-top: 0.25em;
  vertical-align: top;
  width: 1em;
  height: 1em;
  border-radius: 50px;
}
.form-check-inline > input[type='checkbox']:not(:checked) {
  border-radius: 2px;
}
.hover-blue {
  letter-spacing: 1px;
}
.hover-blue:hover {
  background-color: #11263d !important;
  border-color: #11263d !important;
  color: #fff !important;
}
</style>
