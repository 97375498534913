<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="217" height="50" viewBox="732 279 217 50">
    <desc>FHNW Logo Deutsch</desc>
    <g fill="none">
      <g fill="#1A1918" fill-rule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <path d="M767.4 328.6h1.7v-48.9h-1.7z" />
                <path
                  d="M742 295.9c.8-1.1 2.6-4.6 8-4.6 3.8 0 5.8 1.5 6.6 2.2 2.3 2.2 2.5 5.5 2.5 7.3v15.5h-9.7v-13.6c0-.3 0-3.5-3.3-3.5-3.4 0-3.5 3.1-3.5 4v13.1h-9.8v-24.4h9.2v4"
                />
                <path
                  d="M812.2 291.4c-1.8 0-3.3 1.5-3.3 3.3 0 1.8 1.5 3.3 3.3 3.3 1.7 0 2.4-1.2 2.4-1.1.9 2.8-4.1 17.6-10.9 17.6-2.2 0-2.9-1.8-2.9-4.1 0-2.7.8-5.5 1.8-9.2 1.1-3.7 2-7.2 2.5-9.1h-6.6c-.9 3.2-7.3 22-12.8 22-.7 0-1.4-.7-1.4-2 0-.7.6-3.3 1-4.2l2.2-7.3c.5-1.5 1.2-3.5 1.2-5 0-2.6-1.8-4-3.9-4-6 0-8.4 5.3-9.7 7.4l1 .6c.6-.9 3.2-5.4 5-5.4.3 0 .5.3.5 1.2 0 .6-.4 2-.7 3.3l-2.4 7.3c-.4 1.4-1.3 4-1.3 5.8 0 3.4 2.6 5.2 5 5.2 8.5 0 12.1-10.1 13.3-13l.1.1c-.2.6-.9 3.6-.9 5.2 0 4.5 2 7.7 6.5 7.7 12.8 0 19.8-25.6 11-25.6"
                />
                <path d="M840.2 301.4v-9.5h5.7v1.1h-4.4v3h4.1v1.2h-4.1v4.2h-1.3" />
                <path
                  d="M851 297.3c0-1.1-.1-1.8-1.4-1.8-.6 0-1.2.4-1.3 1.1H847c.1-1.5 1.2-2 2.5-2 1.4 0 2.7.4 2.7 2.3v3.1c0 .3 0 .8.1 1.4h-.9c-.1 0-.3.1-.4-.1 0-.3-.1-.6-.1-.9-.4.8-1.2 1.2-2.1 1.2-1.2 0-2.3-.6-2.3-1.9 0-1.9 1.7-2.4 3.3-2.4h1.2zm-1.3.9c-.8 0-1.8.3-1.8 1.3 0 .8.6 1.2 1.3 1.2 1.5 0 1.8-1.3 1.7-2.5h-1.2z"
                />
                <path
                  d="M858.3 296.9c-.1-.8-.5-1.4-1.4-1.4-1.2 0-1.7 1-1.7 2.6s.5 2.6 1.7 2.6c.8 0 1.4-.5 1.4-1.5h1.3c-.1 1.5-1.2 2.4-2.7 2.4-2.1 0-3-1.5-3-3.5 0-1.9 1-3.6 3.1-3.6 1.4 0 2.5.9 2.5 2.3h-1.2"
                />
                <path
                  d="M861.4 301.4v-9.5h1.3v3.8c.5-.7 1.2-1.1 2.2-1.1 1.7 0 2.3 1.1 2.3 2.3v4.5h-1.3v-3.9c0-1.2-.3-1.9-1.5-1.9-.5 0-1.3.4-1.5.9-.3.7-.2 1.2-.2 1.5v3.4h-1.3"
                />
                <path
                  d="M869.5 301.4v-9.5h1.3v3.8c.5-.7 1.2-1.1 2.2-1.1 1.7 0 2.3 1.1 2.3 2.3v4.5H874v-3.9c0-1.2-.3-1.9-1.5-1.9-.5 0-1.3.4-1.5.9-.3.7-.2 1.2-.2 1.5v3.4h-1.3"
                />
                <path
                  d="M883.5 298.1c0 2.1-1.1 3.5-3.3 3.5-2.2 0-3.3-1.5-3.3-3.5s1.1-3.5 3.3-3.5c2.2 0 3.3 1.5 3.3 3.5zm-3.3 2.6c1.5 0 2-1.2 2-2.6 0-1.4-.5-2.6-2-2.6s-2 1.2-2 2.6c0 1.4.4 2.6 2 2.6z"
                />
                <path
                  d="M889.1 296.9c-.1-.8-.5-1.4-1.4-1.4-1.2 0-1.7 1-1.7 2.6s.5 2.6 1.7 2.6c.8 0 1.4-.5 1.4-1.5h1.3c-.1 1.5-1.2 2.4-2.7 2.4-2.1 0-3-1.5-3-3.5 0-1.9 1-3.6 3.1-3.6 1.4 0 2.5.9 2.5 2.3h-1.2"
                />
                <path
                  d="M892.2 301.4v-9.5h1.3v3.8c.5-.7 1.2-1.1 2.2-1.1 1.8 0 2.3 1.1 2.3 2.3v4.5h-1.3v-3.9c0-1.2-.3-1.9-1.5-1.9-.5 0-1.3.4-1.5.9-.3.7-.2 1.2-.2 1.5v3.4h-1.3"
                />
                <path
                  d="M902.3 294.6c1.3 0 2.7.5 2.6 2h-1.3c.1-.8-.6-1.1-1.3-1.1-.7 0-1.2.3-1.2 1.1 0 .7 1.1.8 1.6.9 1.1.4 2.4.5 2.4 2 0 1.6-1.6 2.1-3 2.1s-2.6-.6-2.6-2.2h1.3c0 .8.6 1.3 1.4 1.3.7 0 1.5-.3 1.5-1.1 0-1.5-4.1-.4-4.1-3 .1-1.4 1.5-2 2.7-2"
                />
                <path
                  d="M910.7 296.9c-.1-.8-.5-1.4-1.4-1.4-1.2 0-1.7 1-1.7 2.6s.5 2.6 1.7 2.6c.8 0 1.4-.5 1.4-1.5h1.3c-.1 1.5-1.2 2.4-2.7 2.4-2.1 0-3-1.5-3-3.5 0-1.9 1-3.6 3.1-3.6 1.4 0 2.5.9 2.5 2.3h-1.2"
                />
                <path
                  d="M913.8 301.4v-9.5h1.3v3.8c.5-.7 1.2-1.1 2.2-1.1 1.7 0 2.3 1.1 2.3 2.3v4.5h-1.3v-3.9c0-1.2-.3-1.9-1.5-1.9-.5 0-1.3.4-1.5.9-.3.7-.2 1.2-.2 1.5v3.4h-1.3"
                />
                <path
                  d="M926.1 294.8h1.3v6.6h-1.2v-1c-.5.8-1.4 1.2-2.3 1.2-1.4 0-2.2-1.1-2.2-2.4v-4.4h1.3v3.9c0 1.1.3 2 1.5 2 .5 0 1.2-.3 1.5-1 .2-.6.3-1.4.3-1.6v-3.3"
                />
                <path d="M929.5 301.4h1.2v-9.5h-1.2z" />
                <path
                  d="M933.9 298.4c-.1 1.2.4 2.4 1.7 2.4 1 0 1.5-.4 1.6-1.3h1.3c-.2 1.5-1.4 2.3-3 2.3-2.1 0-3-1.5-3-3.5 0-1.9 1-3.6 3.1-3.6 2 0 2.9 1.3 2.9 3.1v.6h-4.6zm3.4-.9c0-1.1-.5-2-1.7-2-1.1 0-1.7.9-1.7 2h3.4z"
                />
                <path d="M847.9 306.8v9.5h-1.6l-4.9-8.1v8.1h-1.2v-9.5h1.8l4.7 7.9v-7.9h1.2" />
                <path
                  d="M856.4 313c0 2.1-1.1 3.5-3.3 3.5-2.2 0-3.3-1.5-3.3-3.5 0-2.1 1.1-3.5 3.3-3.5 2.2 0 3.3 1.5 3.3 3.5zm-3.3 2.6c1.5 0 2-1.2 2-2.6 0-1.4-.5-2.6-2-2.6s-2 1.2-2 2.6c0 1.4.5 2.6 2 2.6z"
                />
                <path
                  d="M859.4 316.3h-1.3v-6.6h1.3v1.3c.2-.9 1-1.4 2-1.4h.4v1.2c-.2-.1-.4-.1-.5-.1-1.6.1-1.9.9-1.9 2.3v3.3"
                />
                <path
                  d="M867.4 306.8h1.3v8.4c0 .4 0 .8.1 1.2h-1.3v-1.1c-.5.9-1.3 1.3-2.3 1.3-2 0-2.8-1.8-2.8-3.5 0-1.8.9-3.5 3-3.5.9 0 1.7.3 2.1 1.1v-3.9h-.1zm-1.9 8.9c1.5 0 1.9-1.4 1.9-2.7 0-1.2-.5-2.6-1.9-2.6-1.5 0-1.9 1.4-1.9 2.6 0 1.2.5 2.7 1.9 2.7z"
                />
                <path
                  d="M880.7 309.7l-2.4 6.6h-1.4l-1.6-5.4-1.7 5.4h-1.5l-2.2-6.6h1.3l1.7 5.3 1.7-5.3h1.6l1.5 5.2 1.7-5.2h1.3"
                />
                <path
                  d="M882.8 313.3c-.1 1.2.4 2.4 1.7 2.4 1 0 1.5-.4 1.6-1.3h1.3c-.2 1.5-1.4 2.3-3 2.3-2.1 0-3-1.5-3-3.5 0-1.9 1-3.6 3.1-3.6 2 0 2.9 1.3 2.9 3.1v.6h-4.6zm3.4-.9c0-1.1-.5-2-1.7-2-1.1 0-1.7.9-1.7 2h3.4z"
                />
                <path
                  d="M891.5 309.5c1.3 0 2.7.5 2.6 2h-1.3c.1-.8-.6-1.1-1.3-1.1-.7 0-1.2.3-1.2 1.1 0 .7 1.1.8 1.6.9 1.1.4 2.4.5 2.4 2 0 1.6-1.6 2.1-3 2.1s-2.6-.6-2.6-2.2h1.3c0 .8.6 1.3 1.4 1.3.7 0 1.5-.3 1.5-1.1 0-1.5-4.1-.4-4.1-3 .1-1.4 1.5-2 2.7-2"
                />
                <path
                  d="M896.5 309.7v-1.4l1.3-.4v1.8h1.5v.9h-1.5v4.3c0 .5.4.7.8.7.2 0 .5 0 .7-.1v1c-.3 0-.6.1-.9.1-.3 0-.5 0-.9-.1-.2 0-.5-.1-.7-.4-.2-.3-.3-.3-.3-1.2v-4.2h-1.1v-.9l1.1-.1"
                />
                <path
                  d="M903.1 309.5c1.3 0 2.7.5 2.6 2h-1.3c.1-.8-.6-1.1-1.3-1.1-.7 0-1.2.3-1.2 1.1 0 .7 1.1.8 1.6.9 1.1.4 2.4.5 2.4 2 0 1.6-1.6 2.1-3 2.1s-2.6-.6-2.6-2.2h1.3c0 .8.6 1.3 1.4 1.3.7 0 1.5-.3 1.5-1.1 0-1.5-4.1-.4-4.1-3 .1-1.4 1.5-2 2.7-2"
                />
                <path
                  d="M911.5 311.8c-.1-.8-.5-1.4-1.4-1.4-1.2 0-1.7 1-1.7 2.6s.5 2.6 1.7 2.6c.8 0 1.4-.5 1.4-1.5h1.3c-.1 1.5-1.2 2.4-2.7 2.4-2.1 0-3-1.5-3-3.5 0-1.9 1-3.6 3.1-3.6 1.4 0 2.5.9 2.6 2.3h-1.3"
                />
                <path
                  d="M914.5 316.3v-9.5h1.3v3.8c.5-.7 1.2-1.1 2.2-1.1 1.7 0 2.3 1.1 2.3 2.3v4.5H919v-3.9c0-1.2-.3-1.9-1.5-1.9-.5 0-1.3.4-1.5.9-.3.7-.2 1.2-.2 1.5v3.4h-1.3"
                />
                <path
                  d="M932.1 309.7l-2.4 6.6h-1.4l-1.6-5.4-1.7 5.4h-1.5l-2.2-6.6h1.4l1.6 5.3 1.7-5.3h1.6l1.5 5.2 1.7-5.2h1.3"
                />
                <path
                  d="M934.2 313.3c-.1 1.2.4 2.4 1.7 2.4 1 0 1.5-.4 1.6-1.3h1.3c-.2 1.5-1.4 2.3-3 2.3-2.1 0-3-1.5-3-3.5 0-1.9 1-3.6 3.1-3.6 2 0 2.9 1.3 2.9 3.1v.6h-4.6zm3.4-.9c0-1.1-.5-2-1.7-2-1.1 0-1.7.9-1.7 2h3.4z"
                />
                <path d="M940.4 308.2h1.5v-1.4h-1.5v1.4zm.2 8.1h1.3v-6.6h-1.3v6.6z" />
                <path d="M948.6 316.3h-4.9v-.8l3.4-4.9h-3.3v-.9h4.6v.9l-3.3 4.9h3.5v.8" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
