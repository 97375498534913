<template>
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="max-height: 70px; max-width: 70px;"
  >
    <g clip-path="url(#clip0_243_2778)">
      <rect width="512" height="512" fill="white" />
      <path
        d="M63.0693 159.5V338H133.569V283H184.069C210.569 283 197.819 319.5 205.569 338H282.569C277.819 332.25 274.319 324.5 273.819 316.75L270.819 277.5C269.819 264 262.569 253.75 250.569 250.5V250C270.069 244.25 278.069 227.75 278.069 209.25C278.069 169.75 248.569 159.5 213.569 159.5H63.0693ZM133.569 238V204.5H183.569C196.569 204.5 203.069 209.5 203.069 221.25C203.069 232.75 197.069 238 184.319 238H133.569Z"
        fill="black"
      />
      <path
        d="M354.698 186.2V240.5H300.398V283.7H354.698V338H397.898V283.7H452.198V240.5H397.898V186.2H354.698Z"
        fill="#CCCCB0"
      />
    </g>
    <defs>
      <clipPath id="clip0_243_2778">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
