<script setup>
import TitleBlock from './../components/TitleBlock.vue'
import DownloadIcon from './../components/assets/DownloadIcon.vue'
import Carousel from './../components/Carousel.vue'

import { onMounted, reactive } from 'vue'
import axios from 'axios'

const baseURL = import.meta.env.VITE_URL
let main = reactive([])
let gebaeude = reactive([])
let wohnen = reactive([])
let gewerbe = reactive([])
let state = reactive({
  selectedOption: 'Patiohäuser'
})

const downloadItem = (url) => {
  axios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = url.split('/')[4]
      link.click()
      URL.revokeObjectURL(link.href)
    })
    .catch(console.error)
}

const getMain = () => {
  axios.get(`${baseURL}/api/ausbaus?populate=*,bg_image`).then(async (res) => {
    main.value = await res.data.data[0].attributes
  })
}

const getGebaeude = () => {
  axios
    .get(
      `${baseURL}/api/ausbau-gebaeude-tabs?populate=*,Carousel.image,section.image,Faq,section.download_button`
    )
    .then(async (res) => {
      gebaeude.value = await res.data.data[0].attributes
      console.log(gebaeude)
    })
}

const getWohnen = () => {
  axios.get(`${baseURL}/api/ausbau-wohnen-tabs?populate=*,carousel.image,faq,wohnen,maisonette,etagenwohnungen`).then(async (res) => {
    // console.log(res)
    wohnen.value = await res.data.data[0].attributes
  })
}

const getGewerbe = () => {
  axios.get(`${baseURL}/api/ausbau-gewerbe-tabs?populate=*,image,faq`).then(async (res) => {
    // console.log(res)
    gewerbe.value = await res.data.data[0].attributes
  })
}

onMounted(getMain)
onMounted(getGebaeude)
onMounted(getWohnen)
onMounted(getGewerbe)
</script>

<template>
  <div v-if="main.value && gewerbe.value && wohnen.value && gebaeude.value">
    <section class="page-header section-bg">
      <img
      
        :src="baseURL + main?.value?.bg_image.data.attributes.url"
        class="w-100 ausbau-hero"
        alt="Header background"
      />
    </section>
    <section>
      <div class="container">
        <title-block :content="main?.value?.title" passedClass="softline" />

        <nav class="position-relative z-1">
          <div class="nav nav-tabs mt-5 mt-lg-0 mb-3 mb-lg-5" id="nav-tab" role="tablist">
            <button
              class="nav-link active"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Gebäude
            </button>
            <button
              class="nav-link"
              id="nav-wohnen-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-wohnen"
              type="button"
              role="tab"
              aria-controls="nav-wohnen"
              aria-selected="false"
            >
              Wohnen
            </button>
            <button
              class="nav-link"
              id="nav-gewerbe-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-gewerbe"
              type="button"
              role="tab"
              aria-controls="nav-gewerbe"
              aria-selected="false"
            >
              Gewerbe
            </button>
            <div class="ms-lg-auto mt-5 mt-md-0 me-lg-5 btn-group" role="group">
              <button
                @click.prevent="
                  downloadItem(
                    baseURL + gebaeude?.value?.section[0].download_button.data.attributes.url
                  )
                "
                class="nav-link btn border-1 rounded-0 ms-auto"
              >
                Kurzbaubeschrieb
              </button>
              <button
                @click.prevent="
                  downloadItem(
                    baseURL + gebaeude?.value?.section[0].download_button.data.attributes.url
                  )
                "
                type="button"
                class="btn btn-dark border-2 btn-sm rounded-0 px-2"
                aria-label="Download Icon"
              >
                <DownloadIcon style="scale: 0.8" />
              </button>
            </div>
          </div>
        </nav>
        <div class="tab-content pt-md-4" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
            tabindex="0"
          >
            <div class="row mb-5 pb-5">
              <div class="col-lg-4">
                <h2
                  class="text-uppercase text-secondary font-helvetica-black mt-3 mb-4"
                  v-html="gebaeude?.value?.tab_title"
                ></h2>
              </div>
              <div class="col-lg-8 ps-lg-5">
                <div class="accordion show" id="accordion1">
                  <!--                <div class="accordion-item">-->
                  <!--                  <h2 class="accordion-header">-->
                  <!--                    <button-->
                  <!--                      class="accordion-button"-->
                  <!--                      type="button"-->
                  <!--                      data-bs-toggle="collapse"-->
                  <!--                      data-bs-target="#collapseOne"-->
                  <!--                      aria-expanded="true"-->
                  <!--                      aria-controls="collapseOne"-->
                  <!--                    >-->
                  <!--                      {{ item }}-->
                  <!--                    </button>-->
                  <!--                  </h2>-->
                  <!--                  <div-->
                  <!--                    id="collapseOne"-->
                  <!--                    class="accordion-collapse collapse show"-->
                  <!--                    data-bs-parent="#accordion1"-->
                  <!--                  >-->
                  <!--                    <div class="accordion-body">-->
                  <!--                      <strong>This is the first item's accordion body.</strong> It is shown by-->
                  <!--                      default, until the collapse plugin adds the appropriate classes that we use to-->
                  <!--                      style each element. These classes control the overall appearance, as well as-->
                  <!--                      the showing and hiding via CSS transitions. You can modify any of this with-->
                  <!--                      custom CSS or overriding our default variables. It's also worth noting that-->
                  <!--                      just about any HTML can go within the <code>.accordion-body</code>, though the-->
                  <!--                      transition does limit overflow.-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                </div>-->

                  <div class="accordion-item" v-for="(item, i) in gebaeude?.value?.Faq" :key="i">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#' + 'a' + i"
                        aria-expanded="false"
                        :aria-controls="'collapse' + 'a' + i"
                        v-html="item.title"
                      >
                      </button>
                    </h2>
                    <div
                      :id="'a' + i"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion1"
                    >
                      <div class="accordion-body" v-html="item.text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Carousel :data="gebaeude?.value?.Carousel" />

            <h3
              class="has-subtitle-border font-helvetica-black fs-48px pb-md-4"
              v-html="gebaeude?.value?.section[0].title"
            ></h3>
            <div class="ratio ratio-16x9 position-relative">
            <iframe
              width="100%"
              height="650"
              src="https://www.youtube.com/embed/oGPKMa4JO6w?controls=0&showinfo=0&frameborder=0&rel=0&playlist=oGPKMa4JO6w&loop=1&autoplay=1&mute=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              id="NACHALTIGER"
            ></iframe>
            </div>
            <!--            <img-->
            <!--              :src="baseURL + gebaeude?.value?.section[0].image.data.attributes.url"-->
            <!--              class="w-100 mt-5 position-relative z-1 mb-3 mb-md-0"-->
            <!--              alt="Gallery image"-->
            <!--              id="section"-->
            <!--            />-->

            <div class="has-white-polygon pt-3 px-lg-4 mb-5 pb-5">
              <div class="row">
                <div class="col-md-6 col-lg-4 ms-lg-auto">
                  <p class="mb-0" v-html="gebaeude?.value?.section[0].first_column_text"></p>
                </div>
                <div class="col-md-6 col-lg-4 ms-lg-5">
                  <p class="mb-0" v-html="gebaeude?.value?.section[0].second_column_text"></p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-wohnen"
            role="tabpanel"
            aria-labelledby="nav-wohnen-tab"
            tabindex="0"
          >
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="form-check form-check-inline">
                  <input
                    v-model="state.selectedOption"
                    checked
                    type="radio"
                    name="inlineRadioOptions"
                    id="Patiohäuser"
                    value="Patiohäuser"
                  />
                  <label class="form-check-label" for="Patiohäuser">Patiohäuser</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="state.selectedOption"
                    type="radio"
                    name="inlineRadioOptions"
                    id="Loftwohnungen"
                    value="Loftwohnungen"
                  />
                  <label class="form-check-label" for="Loftwohnungen">Loftwohnungen</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="state.selectedOption"
                    type="radio"
                    name="inlineRadioOptions"
                    id="AtelierLofts"
                    value="Atelier-Lofts"
                  />
                  <label class="form-check-label" for="AtelierLofts">Atelier-Lofts</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="state.selectedOption"
                    type="radio"
                    name="inlineRadioOptions"
                    id="Etagewohnungen"
                    value="Etagewohnungen"
                  />
                  <label class="form-check-label" for="Etagewohnungen">Etagewohnungen</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="state.selectedOption"
                    type="radio"
                    name="inlineRadioOptions"
                    id="maisonettewohnung1"
                    value="Maisonettewohnung"
                  />
                  <label class="form-check-label" for="maisonettewohnung1">Maisonettewohnung</label>
                </div>
              </div>
              <div class="col-md-4">
                <h3 class="text-uppercase text-secondary font-helvetica-black mt-3">
                  {{ state.selectedOption }}
                </h3>
              </div>
              <div class="col-md-8 ps-lg-5">
                <div class="accordion show" id="accordion1">
                  <div class="accordion-item" v-for="(item, i) in wohnen?.value?.faq" :key="i">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#' + 'a' + i"
                        aria-expanded="false"
                        :aria-controls="'collapse' + 'a' + i"
                        v-html="item.title"
                      >
                      </button>
                    </h2>
                    <div
                      :id="'a' + i"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion1"
                    >
                      <div class="accordion-body" v-html="item.text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-5"></div>
                        <Carousel
                          :data="wohnen?.value?.carousel"
                          v-if="state.selectedOption === 'Loftwohnungen'"
                        />
            <iframe
              src="https://biganto.com/tour/37612"
              class="w-100 my-5"
              style="aspect-ratio: 1.75"
              v-if="state.selectedOption === 'Atelier-Lofts'"
              frameborder="0"
            ></iframe>
            <iframe
              src="https://biganto.com/tour/37768"
              class="w-100 my-5"
              style="aspect-ratio: 1.75"
              v-if="state.selectedOption === 'Patiohäuser'"
              frameborder="0"
            ></iframe>
            <img :src="baseURL + wohnen?.value?.maisonette.data.attributes.url" v-if="state.selectedOption === 'Maisonettewohnung'" class="w-100" style="max-height: 750px;object-fit: cover;" alt="">
            <img :src="baseURL + wohnen?.value?.etagenwohnungen.data.attributes.url" v-if="state.selectedOption === 'Etagewohnungen'" class="w-100" style="max-height: 750px;object-fit: cover;" alt="">
          </div>
          <div
            class="tab-pane fade"
            id="nav-gewerbe"
            role="tabpanel"
            aria-labelledby="nav-gewerbe-tab"
            tabindex="0"
          >
            <!--          <div class="row">-->
            <!--            <div class="col-md-4">-->
            <!--              <h2 class="text-uppercase text-secondary font-helvetica-black mt-3">GEWERBE</h2>-->
            <!--            </div>-->
            <!--            <div class="col-md-8 ps-lg-5"></div>-->
            <!--          </div>-->
            <div class="row mb-5 pb-5">
              <div class="col-lg-4">
                <h2
                  class="text-uppercase text-secondary font-helvetica-black mt-3 mb-4"
                  v-html="gewerbe?.value?.tab_title"
                ></h2>
              </div>
              <div class="col-lg-8 ps-lg-5">
                <div class="accordion show" id="accordion1">
                  <div class="accordion-item" v-for="(item, i) in gewerbe?.value?.faq" :key="i">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#' + 'a' + i"
                        aria-expanded="false"
                        :aria-controls="'collapse' + 'a' + i"
                        v-html="item.title"
                      >
                      </button>
                    </h2>
                    <div
                      :id="'a' + i"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion1"
                    >
                      <div class="accordion-body" v-html="item.text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-5"></div>
            <div class="section-bg mt-0">
              <img
                :src="baseURL + gewerbe?.value?.image.data.attributes.url"
                class="w-100 img-fluid"
                alt="Section Background"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
#nav-wohnen {
  accent-color: #8d8d50;
}
.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-active-color: inherit;
  --bs-accordion-active-bg: transparent;
}
.accordion-item {
  border-bottom: 1px solid #ccccb0;
}
.accordion-button {
  text-transform: uppercase;
  padding-right: 8px;
}
.accordion-button::after {
  filter: grayscale(1);
}
.nav-tabs {
  --bs-nav-tabs-border-width: 2px;
  --bs-nav-tabs-border-color: var(--bs-primary);
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-tabs-link-hover-border-color: var(--bs-primary);
  --bs-nav-tabs-link-active-color: #fff;
  --bs-nav-tabs-link-active-bg: var(--bs-primary);
  --bs-nav-tabs-link-active-border-color: var(--bs-primary);
}
.nav-tabs .nav-link:not(.active) {
  color: var(--bs-primary) !important;
}
.nav-tabs .nav-link {
  border-color: var(--bs-primary);
  margin-right: -2px;
  border-bottom: 0 !important;
}

@media screen and (max-width: 992px) {
  .nav-tabs {
    border-bottom: 0;
  }
  .nav-tabs .nav-link {
    border-bottom: 1px solid var(--bs-primary) !important;
  }
}

.has-subtitle-border {
  margin-top: clamp(9.375rem, 6.25rem + 10vw, 13.75rem);
}
.has-subtitle-border:after {
  top: -140px;
  right: -210px;
  bottom: -120px;
  left: -330px;
  transform: scaleX(-1);
}

.has-white-polygon:before {
  left: 10%;
  right: -900px;
  transform: scaleX(-1);
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
}

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-bottom: -1px;
}
.ausbau-hero {
  object-position: center !important;
}
</style>
