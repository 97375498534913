<script setup>
import { onMounted, reactive } from 'vue'
import Carousel from '../components/Carousel.vue'
import axios from 'axios'

const baseURL = import.meta.env.VITE_URL
let content = reactive([])

const getContent = () => {
  axios
    .get(`${baseURL}/api/geschichtes?populate=*,section.image,carousel.image`)
    .then(async (res) => {
      content.value = await res.data.data[0].attributes
      // console.log(content)
    })
}

onMounted(getContent)
</script>

<template>
  <div v-if="content.value">
    <section class="container">
      <h1
        class="font-helvetica-black fs-60px mt-4 mb-5 pb-lg-5"
        v-html="content?.value?.page_title"
      ></h1>

      <div class="row align-items-lg-center">
        <div class="col-lg-6 pe-lg-5">
          <img
            :src="baseURL + content?.value?.section[0].image?.data?.attributes?.url"
            class="img-fluid"
            alt="Gallery image"
          />
        </div>
        <div class="col-lg-6 ps-lg-5">
          <h3
            class="font-helvetica-normal text-secondary fs-48px mb-4"
            v-html="content?.value?.section[0]?.title"
          ></h3>
          <p class="lead">
            {{ content?.value?.section[0]?.first_column_text }}
          </p>
        </div>
      </div>

      <div class="row align-items-lg-center">
        <div class="col-lg-6 ps-lg-5 order-lg-2">
          <img
            :src="baseURL + content?.value?.section[1].image?.data?.attributes?.url"
            class="img-fluid"
            alt="Gallery image"
          />
        </div>
        <div class="col-lg-6 pe-lg-5 order-lg-1">
          <h3
            class="font-helvetica-normal text-secondary fs-48px mb-4"
            v-html="content?.value?.section[1]?.title"
          ></h3>
          <p class="lead">
            {{ content?.value?.section[1]?.first_column_text }}
          </p>
        </div>
      </div>

      <br />
      <br />
      <h3
        class="font-helvetica-normal text-secondary fs-48px mt-5 pt-lg-5 mb-4"
        v-html="content?.value?.carousel[0]?.carousel_title"
      ></h3>
    </section>

    <Carousel :data="content?.value?.carousel" />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<style scoped></style>