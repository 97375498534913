import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HistoryView from '../views/HistoryView.vue'
import LocationView from '../views/LocationView.vue'
import OfferView from '../views/OfferView.vue'
import ExpansionView from '../views/ExpansionView.vue'
import TogetherView from '../views/TogetherView.vue'
import ContactView from '../views/ContactView.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from '../views/Datenschutz.vue'

// import Ausbau from '../views/Ausbau.vue'
// import axios from 'axios'

// const baseURL = import.meta.env.VITE_URL

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  linkActiveClass: 'active',
  routes: [
    {
      name: 'HomeView',
      path: '/',
      component: HomeView
    },
    {
      name: 'LocationyView',
      path: '/lage',
      component: LocationView
    },
    {
      name: 'HistoryView',
      path: '/geschichte',
      component: HistoryView
    },
    {
      name: 'OfferView',
      path: '/angebot',
      component: OfferView
    },
    {
      name: 'ExpansionView',
      path: '/ausbau',
      component: ExpansionView
    },
    {
      name: 'TogetherView',
      path: '/together',
      component: TogetherView
    },
    {
      name: 'ContactView',
      path: '/kontakt',
      component: ContactView
    },
    {
      name: 'Impressum',
      path: '/impressum',
      component: Impressum
    },
    {
      name: 'Datenschutz',
      path: '/datenschutz',
      component: Datenschutz
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (to.hash) {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            let top = element.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: top, behavior: 'smooth' });
          } else {
            resolve(savedPosition || { x: 0, y: 0 });
          }
        }, 700);
      } else if (savedPosition) {
        resolve(savedPosition);
      } else {
        resolve({ x: 0, y: 0 });
      }
    });
  }
})
export default router
