<script setup>
// import { RouterLink } from 'vue-router'
import { onMounted, reactive } from 'vue'
// // import Carousel from '../components/Carousel.vue'
import axios from 'axios'
import Map from '../components/Map.vue'

const baseURL = import.meta.env.VITE_URL

let lageFirstTable = reactive([])
let lageSecondTable = reactive([])
let content = reactive([])

const getLageFirstTableData = () => {
  axios.get(`${baseURL}/api/location-first-tables`).then(async (res) => {
    lageFirstTable.value = await res.data.data
  })
}

const getLageSecondTableData = () => {
  axios.get(`${baseURL}/api/location-second-tables`).then(async (res) => {
    lageSecondTable.value = await res.data.data
    // console.log(lageFirstTable)
  })
}

const getContent = () => {
  axios.get(`${baseURL}/api/lages?populate=*,section.image`).then(async (res) => {
    content.value = await res.data.data[0].attributes
  })
}

onMounted(getLageFirstTableData)
onMounted(getLageSecondTableData)
onMounted(getContent)
</script>

<template>
  <div v-if="content.value">


  <section class="container">
    <h1
        class="font-helvetica-black fs-60px mt-4 mb-5"
        v-html="content?.value?.page_title"
    ></h1>


    <Map />

    <div class="row mt-5 align-items-end">
      <div class="col-lg-6 bg-clip-path">
        <img :src="baseURL + content?.value?.small_map_pic_path" class="w-100 img-fluid" alt="Gallery image" />
      </div>
      <div class="col-lg-6 ps-lg-3">
        <h3
            class="text-primary font-helvetica-black text-uppercase"
            v-html="content?.value?.first_table_title"
        ></h3>

        <ul class="list-unstyled pe-4 mt-5 mb-0">
          <li class="d-flex justify-content-between py-2 text-primary">
            <span class="col-4"></span>
            <span class="col-4 text-center"
            ><svg
                width="16"
                height="22"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8 0C4 0 0 0.578441 0 4.62753V15.6179C0 17.8507 1.57 19.667 3.5 19.667L2 21.4023V21.9808H4.23L6.23 19.667H10L12 21.9808H14V21.4023L12.5 19.667C14.43 19.667 16 17.8507 16 15.6179V4.62753C16 0.578441 12.42 0 8 0ZM3.5 17.3532C2.67 17.3532 2 16.5781 2 15.6179C2 14.6577 2.67 13.8826 3.5 13.8826C4.33 13.8826 5 14.6577 5 15.6179C5 16.5781 4.33 17.3532 3.5 17.3532ZM7 9.25506H2V4.62753H7V9.25506ZM9 9.25506V4.62753H14V9.25506H9ZM12.5 17.3532C11.67 17.3532 11 16.5781 11 15.6179C11 14.6577 11.67 13.8826 12.5 13.8826C13.33 13.8826 14 14.6577 14 15.6179C14 16.5781 13.33 17.3532 12.5 17.3532Z"
                    fill="currentColor"
                />
              </svg>
            </span>
            <span class="col-4 text-center">
              <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M15.92 1.63915C15.72 0.956594 15.16 0.470703 14.5 0.470703H3.5C2.84 0.470703 2.29 0.956594 2.08 1.63915L0 8.56888V17.8239C0 18.4602 0.45 18.9808 1 18.9808H2C2.55 18.9808 3 18.4602 3 17.8239V16.6671H15V17.8239C15 18.4602 15.45 18.9808 16 18.9808H17C17.55 18.9808 18 18.4602 18 17.8239V8.56888L15.92 1.63915ZM3.5 13.1964C2.67 13.1964 2 12.4213 2 11.4611C2 10.5009 2.67 9.72576 3.5 9.72576C4.33 9.72576 5 10.5009 5 11.4611C5 12.4213 4.33 13.1964 3.5 13.1964ZM14.5 13.1964C13.67 13.1964 13 12.4213 13 11.4611C13 10.5009 13.67 9.72576 14.5 9.72576C15.33 9.72576 16 10.5009 16 11.4611C16 12.4213 15.33 13.1964 14.5 13.1964ZM2 7.412L3.5 2.20603H14.5L16 7.412H2Z"
                    fill="currentColor"
                />
              </svg>
            </span>
          </li>
          <li
              class="d-flex justify-content-between py-2 text-primary fw-light border-bottom border-secondary"
              v-for="item in lageFirstTable.value"
          >
            <span class="col-4">{{ item.attributes.place }}</span>
            <span class="col-4 text-center">{{ item.attributes.metro_distance }} Min.</span>
            <span class="col-4 text-center">{{ item.attributes.car_distance }} Min.</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-5 pt-5">
      <div class="col-lg-5 offset-lg-1">
        <h3
            class="text-primary font-helvetica-black mt-5 text-uppercase"
            v-html="content?.value?.second_table_title"
        ></h3>
      </div>
      <div class="col-lg-6 ps-lg-3">
        <ul class="list-unstyled pe-4 mb-0">
          <li class="d-flex justify-content-between py-2 text-primary">
            <span class="col-4"></span>
            <span class="col-4 text-center"
            ><svg
                width="13"
                height="25"
                viewBox="0 0 13 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.5 4.62753C8.6 4.62753 9.5 3.58634 9.5 2.31377C9.5 1.04119 8.6 0 7.5 0C6.4 0 5.5 1.04119 5.5 2.31377C5.5 3.58634 6.4 4.62753 7.5 4.62753ZM3.8 8.56093L1 24.873H3.1L4.9 15.6179L7 17.9317V24.873H9V16.1964L6.9 13.8826L7.5 10.4119C8.8 12.1473 10.8 13.3042 13 13.3042V10.9904C11.1 10.9904 9.5 9.8335 8.7 8.21387L7.7 6.36285C7.42994 5.86327 7.01502 5.49115 6.52949 5.31311C6.04397 5.13506 5.51952 5.1627 5.05 5.39107L0 7.8668V13.3042H2V9.37075L3.8 8.56093Z"
                    fill="currentColor"
                />
              </svg>
            </span>
            <span class="col-4 text-center">
              <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M15.92 1.63915C15.72 0.956594 15.16 0.470703 14.5 0.470703H3.5C2.84 0.470703 2.29 0.956594 2.08 1.63915L0 8.56888V17.8239C0 18.4602 0.45 18.9808 1 18.9808H2C2.55 18.9808 3 18.4602 3 17.8239V16.6671H15V17.8239C15 18.4602 15.45 18.9808 16 18.9808H17C17.55 18.9808 18 18.4602 18 17.8239V8.56888L15.92 1.63915ZM3.5 13.1964C2.67 13.1964 2 12.4213 2 11.4611C2 10.5009 2.67 9.72576 3.5 9.72576C4.33 9.72576 5 10.5009 5 11.4611C5 12.4213 4.33 13.1964 3.5 13.1964ZM14.5 13.1964C13.67 13.1964 13 12.4213 13 11.4611C13 10.5009 13.67 9.72576 14.5 9.72576C15.33 9.72576 16 10.5009 16 11.4611C16 12.4213 15.33 13.1964 14.5 13.1964ZM2 7.412L3.5 2.20603H14.5L16 7.412H2Z"
                    fill="currentColor"
                />
              </svg>
            </span>
          </li>
          <li
              class="d-flex justify-content-between py-2 text-primary fw-light border-bottom border-secondary"
              v-for="(item, i) in lageSecondTable.value" :key="i"
          >
            <span class="col-4">{{ item.attributes.place }}</span>
            <span class="col-4 text-center">{{ item.attributes.foot_distance }} Min.</span>
            <span class="col-4 text-center">{{ item.attributes.car_distance }} Min.</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="section-bg my-5 pt-5">
    <img
        :src="baseURL + content?.value?.long_map_pic_path"
        class="img-fluid w-100"
        alt="Map"
    />
  </section>
  <section class="container">
    <div class="row pt-3 pt-lg-5 align-items-lg-center">
      <div class="col-lg-6 pe-lg-5">
        <img :src="baseURL + content?.value?.section[0].image.data.attributes.url" class="img-fluid" alt="Gallery image" />
      </div>
      <div class="col-lg-6 ps-lg-5">
        <h3
            class="font-helvetica-normal text-secondary fs-48px mb-4"
            v-html="content?.value?.section[0].title"
        ></h3>
        <p class="lead">
          {{ content?.value?.section[0].first_column_text }}
        </p>
      </div>
    </div>

    <div class="row align-items-lg-center">
      <div class="col-lg-6 ps-lg-5 order-lg-2">
        <img :src="baseURL + content?.value?.section[1].image.data.attributes.url" class="img-fluid" alt="Gallery image" />
      </div>
      <div class="col-lg-6 pe-lg-5 order-lg-1">
        <h3
            class="font-helvetica-normal text-secondary fs-48px mb-4"
            v-html="content?.value?.section[1].title"
        ></h3>
        <p class="lead">
          {{ content?.value?.section[1].first_column_text }}
        </p>
      </div>
    </div>
  </section>
  </div>
</template>

<style>
.bg-clip-path {
  clip-path: polygon(0 0, 100% 13%, 100% 100%, 0 100%);
}
</style>

