<script setup>
import TitleBlock from './../components/TitleBlock.vue'
import DownloadIcon from './../components/assets/DownloadIcon.vue'
import RightArrow from './../components/assets/RightArrow.vue'

import {onMounted, reactive} from 'vue'
import axios from "axios";

const baseURL = import.meta.env.VITE_URL
let content = reactive([])

const downloadItem = (url) => {
  axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Flyer Together'
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(console.error)
}

const getContent = () => {
  axios.get(`${baseURL}/api/togethers?populate=*,section.image,section.logo,download_btn`).then(async (res) => {
    content.value = await res.data.data[0].attributes
    // console.log(content)
  })
}

onMounted(getContent)


</script>

<template>
  <div v-if="content.value">
    <section class="page-header section-bg">
      <img :src="baseURL + content?.value?.section[0].image.data.attributes.url" class="w-100" alt="Gallery image" />
    </section>
    <section>
      <div class="container">

        <title-block :content="content?.value?.section[0].title" :description="content?.value?.section[0].first_column_text" />
      </div>
    </section>
    <section class="section-bg mt-5 mt-lg-3">
      <img
          :src="baseURL + content?.value?.section[1].image.data.attributes.url"
        class="img-fluid w-100 z-1 position-relative object-position-center"
        alt="Gallery image"
      />
    </section>
    <section class="mb-5" id="p-4">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="text-bg-on-left bg-primary-centered text-white p-5 p-lg-0">
              <img :src="baseURL + content?.value?.section[1].logo.data.attributes.url" class="mb-5" alt="Logo" />
              <div class="row">
                <div class="col-md-6">
                  <p v-html="content?.value?.section[1].first_column_text">
                  </p>
                </div>
                <div class="col-md-6 ps-md-5">
                  <p v-html="content?.value?.section[1].second_column_text">
                  </p>
                  <a href="#" @click.prevent="downloadItem('https://api.ruetschi-brugg.ch/uploads/Flyer_Together_2019_compressed_fdee4c4e30.pdf')" class="text-white text-decoration-none hover-gold">
                    <DownloadIcon style="scale: 0.8;" />
                    &nbsp; Flyer Kundenbetreuungsmodell Together®
                  </a>

                </div>
              </div>

              <div class="btn-group mt-5 mb-4 mb-lg-0" role="group">
                <button  data-bs-toggle="offcanvas"
                         data-bs-target="#offcanvasRight" type="button" class="btn btn-outline-secondary btn-sm rounded-0 px-3 py-2">
                  Jetzt loslegen
                </button>
                <button  data-bs-toggle="offcanvas"
                         data-bs-target="#offcanvasRight" type="button" class="btn btn-secondary rounded-0" aria-label="Right arrow">
                  <RightArrow style="scale: 0.6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
@media (min-width: 992px) {
  .text-bg-on-left::after {
    right: -290px;
    background-size: 100% 170%;
    display: none;
  }
  .bg-primary-centered {
    padding: 190px !important;
    margin-bottom: -190px;
    margin-top: -190px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE1MSIgaGVpZ2h0PSIxMDA5IiB2aWV3Qm94PSIwIDAgMTE1MSAxMDA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMzEuNSAzOUwxMTUwLjUgMTUzVjEwMDhIMzEuNVYzOVoiIGZpbGw9IiMxMTI2M0QiLz4KPHBhdGggZD0iTTExMTcuNSAxMDA4LjVWMTE4LjU2MkwyLjUgMi41VjEwMDguNSIgc3Ryb2tlPSIjQ0NDQ0IwIiBzdHJva2Utd2lkdGg9IjQiLz4KPC9zdmc+Cg==");
  }
}
.bg-primary-centered {
  background-size: cover;
  background-repeat: no-repeat;
}
.object-position-center {
  object-position: center;
}
</style>
