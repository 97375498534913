<template>
  <svg width="147" height="94" viewBox="0 0 147 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1233_8569)">
    <path opacity="0.2" d="M99.5799 26.2609L74.8761 24.4783L74.7887 31.8696L99.5799 33.4348V26.2609Z" fill="#11263D"/>
    <path opacity="0.2" d="M2.73033 29.3913L22.4323 19.5217V0L2.03137 14.0217L2.73033 29.3913Z" fill="#11263D"/>
    <path opacity="0.2" d="M58.7564 5.76085L29.1379 3.04346V28.3695L59.3243 30.5652L59.2151 25.3261L63.387 25.5869L59.5864 29.6304L59.3898 32.7826L64.8723 33.1087L64.9815 31.3478L64.3699 29.6739L67.9957 25.8695L68.1923 22.4565L59.1277 21.8913L58.7564 5.76085Z" fill="#11263D"/>
    <path opacity="0.2" d="M72.5826 53.1958L77.7375 52.9784V39.9349L72.5826 39.7827V53.1958Z" fill="#11263D"/>
    <path opacity="0.2" d="M78.7203 52.9348L83.897 52.7174V40.1087L78.7203 39.9565V52.9348Z" fill="#11263D"/>
    <path opacity="0.2" d="M84.88 52.6738L90.0567 52.4565V40.2608L84.88 40.1304V52.6738Z" fill="#11263D"/>
    <path opacity="0.2" d="M91.0396 52.4131L96.2162 52.1958V40.4349L91.0396 40.2827V52.4131Z" fill="#11263D"/>
    <path opacity="0.2" d="M97.1991 52.1522L102.354 51.9348V40.6087L97.1991 40.4565V52.1522Z" fill="#11263D"/>
    <path opacity="0.2" d="M103.337 51.8912L108.514 51.6739V40.7608L103.337 40.6304V51.8912Z" fill="#11263D"/>
    <path opacity="0.2" d="M109.628 51.6305L114.782 51.4131V40.9349L109.628 40.8044V51.6305Z" fill="#11263D"/>
    <path opacity="0.2" d="M115.765 51.3696L120.942 51.1522V41.1087L115.765 40.9565V51.3696Z" fill="#11263D"/>
    <path opacity="0.2" d="M121.925 51.1086L127.101 50.8912V41.2825L121.925 41.1304V51.1086Z" fill="#11263D"/>
    <path opacity="0.2" d="M128.084 50.8479L133.261 50.6305V41.4349L128.084 41.3044V50.8479Z" fill="#11263D"/>
    <path opacity="0.2" d="M134.244 50.587L139.399 50.3696V41.6087L134.244 41.4565V50.587Z" fill="#11263D"/>
    <path opacity="0.2" d="M140.382 41.6304V50.326L145.558 50.1086V41.7825L140.382 41.6304Z" fill="#11263D"/>
    <path d="M24.6165 58.826L1.39792 53.7825L0 22.4564L0.546062 22.4347L1.92214 53.3478L24.0486 58.1521V36.6738L4.73982 39.4347L3.01426 15.0434L24.6165 0.6521V36.0434L25.8397 35.8695H25.8615L61.6614 37.1304L61.8143 47.5434H26.9536V58.1086L61.7269 56.4782L61.7487 57.0217L26.4076 58.6738V46.9782H61.2682L61.1153 37.6304L25.8615 36.3695L24.6165 36.5434V58.826ZM3.58217 15.326L5.2422 38.8043L24.0486 36.1086V1.67384L3.58217 15.326Z" fill="#11263D"/>
    <path d="M107.858 55.0434L107.836 54.5217L146.454 52.8912V40.4347L76.0555 38.5217L76.4487 22.326L103.162 17.1304H103.206L147 22.826V53.3912H146.738L107.858 55.0434ZM76.6016 37.9999L146.454 39.8912V23.2825L103.184 17.6521L76.9729 22.7391L76.6016 37.9999Z" fill="#11263D"/>
    <path d="M70.311 56.6304L69.7649 33.5L27.2157 30.913L27.0192 0.521729L61.6395 3.69564V32.4783L69.7431 32.9783L69.5902 25.9565L101.611 28.6087V35.4348L70.2891 33.5217L70.8134 56.0652L107.836 54.5L107.858 55.0217L70.311 56.6304ZM70.2891 33L101.087 34.8696V29.0869L70.1363 26.5217L70.2891 33ZM27.74 30.413L61.1153 32.4348V4.1739L27.5434 1.10869L27.74 30.413Z" fill="#11263D"/>
    <path d="M3.84424 68.3914H16.4255C18.1511 68.3914 19.1777 68.5435 20.0077 68.9348C21.1217 69.4783 22.0609 70.9348 22.0609 72.587C22.0609 74.0001 21.4711 75.5218 19.7237 76.0218V76.0653C20.6193 76.3261 21.3401 77.087 21.4493 78.3914L21.7114 81.674C21.7769 82.3479 22.0609 82.9783 22.454 83.4348H15.9231C15.7047 83 15.7047 82.3479 15.5955 80.587C15.53 79.4783 15.1587 78.8044 14.0447 78.8044H9.82908V83.4348H3.84424V68.3914ZM9.82908 75.0218H14.0665C15.2242 75.0218 15.7266 74.5653 15.7266 73.6087C15.7266 72.4783 14.9839 72.1957 13.9792 72.1957H9.82908V75.0218Z" fill="#11263D"/>
    <path d="M23.9176 68.3914H29.9024V77.5001C29.9024 79.4348 31.0164 80.0435 32.9822 80.0435C34.9262 80.0435 36.062 79.4348 36.062 77.5001V68.3914H42.0469V77.7392C42.0469 82.5218 39.1418 83.9783 33.0041 83.9783C26.8663 83.9783 23.9613 82.5218 23.9613 77.7392V68.3914H23.9176Z" fill="#11263D"/>
    <path d="M35.2538 68.3914H30.6669V71.174H35.2538V68.3914Z" fill="#11263D"/>
    <path d="M48.5995 72.1957H43.0734V68.3914H60.1105V72.1957H54.5844V83.4566H48.5995V72.1957Z" fill="#11263D"/>
    <path d="M64.8722 77.0653C61.9453 76.6305 60.7439 74.9348 60.7439 72.8261C60.7439 68.9348 64.5882 67.8696 69.5028 67.8696C76.2303 67.8696 79.0261 69.6305 79.2227 72.8044H72.2768C72.2768 72.1957 71.9273 71.8044 71.3594 71.6088C70.8352 71.3696 70.1581 71.2827 69.5028 71.2827C67.7335 71.2827 67.1001 71.7174 67.1001 72.3479C67.1001 72.7609 67.2967 73.0435 67.8864 73.1305L75.0289 74.174C78.0432 74.6088 79.8998 76.0001 79.8998 78.5653C79.8998 82.2392 76.8855 83.9566 70.1799 83.9566C65.593 83.9566 60.5692 83.3261 60.5474 78.8914H67.7554C67.7772 79.3914 67.9738 79.7392 68.3888 79.9566C68.8257 80.174 69.4809 80.2827 70.4202 80.2827C72.2768 80.2827 72.801 79.7827 72.801 79.0653C72.801 78.6305 72.517 78.174 71.7307 78.0435L64.8722 77.0653Z" fill="#11263D"/>
    <path d="M101.327 77.587C100.956 79.5653 100.279 81.174 98.7936 82.2609C97.3302 83.3479 95.0367 83.9783 91.389 83.9783C87.7631 83.9783 81.0575 83.7175 81.0575 75.9131C81.0575 68.1088 87.7631 67.8479 91.389 67.8479C94.993 67.8479 100.541 68.3044 101.327 74.2827H94.5998C94.4251 73.2175 93.7043 71.6522 91.4108 71.6522C88.9645 71.6522 87.4355 72.8479 87.4355 75.9131C87.4355 78.9783 88.9426 80.174 91.1269 80.174C93.0053 80.174 94.1412 79.3914 94.6217 77.587H101.327Z" fill="#11263D"/>
    <path d="M116.137 77.4348H109.431V83.4348H103.446V68.3696H109.431V73.6305H116.137V68.3696H122.121V83.4348H116.137V77.4348Z" fill="#11263D"/>
    <path d="M125.114 68.3914H131.099V83.4566H125.114V68.3914Z" fill="#11263D"/>
    <path opacity="0.2" d="M137.433 70.6089H140.491V74.435H144.335V77.4785H140.491V81.3045H137.433V77.4785H133.589V74.435H137.433V70.6089Z" fill="#11263D"/>
    <path d="M6.79303 93.0653C6.33433 93.6957 5.81011 94 4.95826 94C3.47297 94 2.66479 93.0652 2.66479 91.7174C2.66479 90.4131 3.56034 89.4348 5.00194 89.4348C6.22512 89.4348 6.98961 90.1087 7.12066 90.9131H6.64013C6.46539 90.1739 5.81011 89.8479 5.02378 89.8479C3.82245 89.8479 3.16717 90.5652 3.16717 91.7174C3.16717 92.8913 3.75692 93.587 4.93641 93.587C6.24696 93.587 6.66197 92.8479 6.79303 92.0653H5.19852V91.6522H7.20803V93.8913H6.85855L6.79303 93.0653Z" fill="#11263D"/>
    <path d="M8.71521 89.5435H12.1882V89.9565H9.1739V91.4348H12.079V91.8478H9.1739V93.4782H12.2319V93.8913H8.71521V89.5435Z" fill="#11263D"/>
    <path d="M16.4256 90.7827C16.36 90.0435 15.7484 89.8479 15.0495 89.8479C14.3505 89.8479 13.8045 90.1305 13.8045 90.6087C13.8045 91.174 14.3287 91.3044 15.399 91.4783C16.1853 91.6087 16.9935 91.8044 16.9935 92.6305C16.9935 93.6957 16.0106 93.9783 15.1805 93.9783C14.0666 93.9783 13.2584 93.6087 13.1929 92.4348H13.6516C13.7389 93.3479 14.3505 93.5653 15.1805 93.5653C15.8358 93.5653 16.5129 93.3913 16.5129 92.6957C16.5129 92.0218 15.7266 91.9566 14.9184 91.8261C13.87 91.6522 13.3239 91.3696 13.3239 90.674C13.3239 89.7174 14.1976 89.4131 15.0495 89.4131C15.945 89.4131 16.8406 89.7392 16.8624 90.7609H16.4256V90.7827Z" fill="#11263D"/>
    <path d="M22.5414 92.2392C22.4541 93.2174 21.6459 94 20.379 94C18.9593 94 18.0637 93.1087 18.0637 91.7174C18.0637 90.7174 18.6753 89.4348 20.379 89.4348C21.3401 89.4348 22.3667 89.8696 22.4977 91.0218H22.0391C21.908 90.1957 21.1654 89.8696 20.379 89.8696C19.2869 89.8696 18.5443 90.4783 18.5443 91.7392C18.5443 92.7174 19.0466 93.6087 20.379 93.6087C21.4275 93.6087 21.9298 93.087 22.0827 92.2609H22.5414V92.2392Z" fill="#11263D"/>
    <path d="M23.8302 89.5435H24.2889V91.3913H27.4342V89.5435H27.8929V93.8913H27.4342V91.8043H24.2889V93.8913H23.8302V89.5435Z" fill="#11263D"/>
    <path d="M29.4 89.5435H29.8587V93.8913H29.4V89.5435Z" fill="#11263D"/>
    <path d="M35.6688 92.2392C35.5814 93.2174 34.7732 94 33.5063 94C32.0866 94 31.191 93.1087 31.191 91.7174C31.191 90.7174 31.8026 89.4348 33.5063 89.4348C34.4674 89.4348 35.494 89.8696 35.6251 91.0218H35.1664C35.0353 90.1957 34.2927 89.8696 33.5063 89.8696C32.4142 89.8696 31.6716 90.4783 31.6716 91.7392C31.6716 92.7174 32.174 93.6087 33.5063 93.6087C34.5548 93.6087 35.0572 93.087 35.2101 92.2609H35.6688V92.2392Z" fill="#11263D"/>
    <path d="M36.9575 89.5435H37.4162V91.3913H40.5615V89.5435H41.0202V93.8913H40.5615V91.8043H37.4162V93.8913H36.9575V89.5435Z" fill="#11263D"/>
    <path d="M43.7723 89.9565H42.0686V89.5435H45.9566V89.9565H44.2529V93.8913H43.7942V89.9565H43.7723Z" fill="#11263D"/>
    <path d="M47.0487 89.5435H50.5217V89.9565H47.5074V91.4348H50.4125V91.8478H47.5074V93.4782H50.5872V93.8913H47.0705V89.5435H47.0487Z" fill="#11263D"/>
    <path d="M55.4363 89.9565H53.7325V89.5435H57.6205V89.9565H55.9168V93.8913H55.4581V89.9565H55.4363Z" fill="#11263D"/>
    <path d="M58.7126 89.5435H61.0935C61.8361 89.5435 62.5132 89.8043 62.5132 90.7174C62.5132 91.1739 62.2293 91.6522 61.6614 91.7609V91.7826C62.273 91.8695 62.404 92.2826 62.4477 92.9782C62.4696 93.2609 62.4914 93.7826 62.6006 93.8695H62.1201C62.0327 93.7826 62.0109 93.4565 62.0109 92.9782C61.989 92.2174 61.7051 91.9565 60.9624 91.9565H59.215V93.8695H58.7563V89.5435H58.7126ZM59.1713 91.5652H61.0935C61.5959 91.5652 62.0327 91.3913 62.0327 90.7174C62.0327 90.0869 61.5522 89.9565 61.1372 89.9565H59.1713V91.5652Z" fill="#11263D"/>
    <path d="M63.8674 89.5435H64.3261V93.8913H63.8674V89.5435Z" fill="#11263D"/>
    <path d="M65.8114 89.5435H69.0878V89.9565H66.2701V91.4348H68.9567V91.8478H66.2701V93.8913H65.8114V89.5435Z" fill="#11263D"/>
    <path d="M70.2018 89.5435H73.4782V89.9565H70.6605V91.4348H73.3471V91.8478H70.6605V93.8913H70.2018V89.5435Z" fill="#11263D"/>
    <path d="M75.859 89.9565H74.1553V89.5435H78.0432V89.9565H76.3395V93.8913H75.8808V89.9565H75.859Z" fill="#11263D"/>
    <path d="M84.3557 92.5652H82.0622L81.4725 93.8913H80.9919L82.9796 89.5435H83.5038L85.4915 93.8913H84.9673L84.3557 92.5652ZM83.198 90L82.2151 92.1522H84.1373L83.198 90Z" fill="#11263D"/>
    <path d="M90.3843 92.1956C90.3843 92.9783 90.1659 93.9783 88.4403 93.9783C86.7148 93.9783 86.4963 92.9783 86.4963 92.1956V89.5217H86.955V92.1956C86.955 93.3261 87.6977 93.5652 88.4622 93.5652C89.2266 93.5652 89.9693 93.3261 89.9693 92.1956V89.5217H90.428V92.1956H90.3843Z" fill="#11263D"/>
    <path d="M91.8695 89.5435H95.1459V89.9565H92.3282V91.4348H95.0148V91.8478H92.3282V93.8913H91.8695V89.5435Z" fill="#11263D"/>
    <path d="M104.232 89.5435H104.713L103.249 93.8913H102.725L101.415 90.1739H101.393L100.104 93.8913H99.5799L98.1383 89.5435H98.6407L99.842 93.2826H99.8639L101.153 89.5435H101.699L102.987 93.2826H103.009L104.232 89.5435Z" fill="#11263D"/>
    <path d="M107.902 89.4348C109.147 89.4348 110.283 90.1739 110.283 91.7174C110.283 93.2609 109.147 94 107.902 94C106.657 94 105.521 93.2609 105.521 91.7174C105.521 90.1739 106.657 89.4348 107.902 89.4348ZM107.902 93.587C108.863 93.587 109.78 93.0218 109.78 91.7174C109.78 90.4131 108.863 89.8479 107.902 89.8479C106.941 89.8479 106.023 90.4131 106.023 91.7174C106.002 93.0218 106.941 93.587 107.902 93.587Z" fill="#11263D"/>
    <path d="M111.55 89.5435H112.008V91.3913H115.154V89.5435H115.612V93.8913H115.154V91.8043H112.008V93.8913H111.55V89.5435Z" fill="#11263D"/>
    <path d="M117.054 89.5435H117.6L120.658 93.2608H120.68V89.5435H121.138V93.8913H120.614L117.513 90.1739V93.8913H117.054V89.5435Z" fill="#11263D"/>
    <path d="M128.194 89.5435H128.674L127.211 93.8913H126.686L125.376 90.1739L124.087 93.8913H123.563L122.121 89.5435H122.624L123.825 93.2826H123.847L125.136 89.5435H125.682L126.97 93.2826H126.992L128.194 89.5435Z" fill="#11263D"/>
    <path d="M129.744 89.5435H133.217V89.9565H130.181V91.4348H133.086V91.8478H130.181V93.4782H133.261V93.8913H129.744V89.5435Z" fill="#11263D"/>
    <path d="M134.572 89.5435H136.952C137.695 89.5435 138.372 89.8043 138.372 90.7174C138.372 91.1739 138.088 91.6522 137.52 91.7609V91.7826C138.132 91.8695 138.263 92.2826 138.307 92.9782C138.329 93.2609 138.35 93.7826 138.46 93.8695H137.979C137.892 93.7826 137.87 93.4565 137.87 92.9782C137.848 92.2174 137.564 91.9565 136.821 91.9565H135.074V93.8695H134.615V89.5435H134.572ZM135.03 91.5652H136.952C137.455 91.5652 137.892 91.3913 137.892 90.7174C137.892 90.0869 137.411 89.9565 136.996 89.9565H135.03V91.5652Z" fill="#11263D"/>
    <path d="M139.748 89.5435H140.207V91.8913L143.025 89.5435H143.68L141.518 91.3478L143.833 93.8913H143.199L141.168 91.6522L140.207 92.4348V93.8913H139.748V89.5435Z" fill="#11263D"/>
    </g>
    <defs>
      <clipPath id="clip0_1233_8569">
        <rect width="147" height="94" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
