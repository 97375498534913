<template>
  <div class="video-cover">
    <picture v-if="!isInView">
      <source
          media="(max-width: 480px)"
          :srcset="props.coverImage.replace('uploads/', 'uploads/small_')"
      />
      <img ref="elementToCheck"
           :src="props.coverImage"
           class="cover-image"
           alt="Video Cover"
      />
    </picture>
    <div v-if="props.videoSource.includes('youtube') && isInView">
      <div class="ratio ratio-16x9 position-relative" style="z-index: 3">
        <iframe
            :src="props.videoSource.replace('https://api.ruetschi-brugg.ch', '').replace('youtube.com', 'youtube-nocookie.com') +
          '?controls=0&modestbranding=1&autohide=1&showinfo=0&frameborder=0&rel=0&playlist=AfZrM1AjG00&loop=1&autoplay=1&mute=1'"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>



<script setup>
import { ref, onMounted } from 'vue'

const elementToCheck = ref(null)

const isInView = ref(false)

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        isInView.value = true
      }
    })
  })

  observer.observe(elementToCheck.value)

  // Cleanup the observer when the component is unmounted
  return () => observer.disconnect()
})

const props = defineProps({
  coverImage: {
    type: String,
    required: true
  },
  videoSource: {
    type: String,
    required: true
  }
})

</script>

<style scoped>
.video-player,
.cover-image,
.video-cover picture {
  width: 100%;
  aspect-ratio: 1.77;
}
.video-cover {
  position: relative;
  display: inline-block;
  width: 100%;
}

.cover-image,
.play-icon {
  cursor: pointer;
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.cover-image {
  position: relative;
  z-index: 1;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
  z-index: 1;
  scale: 1;
  transition: scale ease-in-out 0.3s;
}
.video-cover:hover .play-icon {
  scale: 1.05;
}
</style>
