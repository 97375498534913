<template>
  <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.00292969 21.5286L16.4315 21.5286V23.5L0.00292978 23.5L0.00292969 21.5286Z"
      fill="white"
    />
    <path
      d="M16.335 14.4698L16.335 12.2515L8.16762 18.8999L0.000278473 12.2515L0.000278376 14.4698L8.16762 21.1182L16.335 14.4698Z"
      fill="white"
    />
    <path
      d="M7.1377 18.9L7.13769 0.500023L9.10912 0.500023L9.10912 18.9L7.1377 18.9Z"
      fill="white"
    />
  </svg>
</template>
