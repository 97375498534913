<script setup>
// import { RouterLink } from 'vue-router'
import { onMounted, reactive } from 'vue'
import RightArrow from './../components/assets/RightArrow.vue'
// import p4Logo from './../components/assets/p4Logo.vue'

import axios from 'axios'

const baseURL = import.meta.env.VITE_URL
let content = reactive([])

const getContent = () => {
  axios
    .get(`${baseURL}/api/kontakts?populate=*,section.image,section.logo,address.logo`)
    .then(async (res) => {
      content.value = await res.data.data[0].attributes
      // console.log(content)
    })
}

onMounted(getContent)
</script>

<template>
  <div v-if="content.value">
    <section class="container position-relative z-2">
      <h1
        class="font-helvetica-black fs-60px mt-4 mb-5 pb-lg-5"
        v-html="content?.value?.page_title"
      ></h1>

      <div class="row align-items-lg-center">
        <div class="col-lg-6 pe-lg-5">
          <img
            :src="baseURL + content?.value?.section[0].image.data.attributes.url"
            class="img-fluid ms-m-70"
            alt="Map"
          />
        </div>
        <div class="col-lg-6 ps-lg-5">
          <h3
            class="font-helvetica-black fs-48px mb-4"
            v-html="content?.value?.section[0].title"
          ></h3>
          <p class="lead">
            {{ content?.value?.section[0].first_column_text }}
          </p>
          <div class="btn-group mt-5" role="group">
            <button
              type="button"
              class="btn btn-outline-dark rounded-0"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              zum Anmelde-Formular
            </button>
            <button type="button"    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight" class="btn btn-dark rounded-0" aria-label="Right arrow">
              <RightArrow style="scale: 0.7" />
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5 mt-5 pt-lg-2">
      <div class="container mt-5 pt-lg-5">
        <div class="row">
          <div class="col-xxl-7 col-lg-8 ms-lg-auto">
            <div class="text-bg-on-left to-right mt-5 text-white p-4 p-lg-0">
              <img
                :src="baseURL + content?.value?.section[1].image.data.attributes.url"
                class="mb-5"
                alt="AARBRUGG AG Logo"
              />
              <div class="row">
                <div class="col-md-6">
                  <p v-html="content?.value?.section[1].first_column_text"></p>
                </div>
                <div class="col-md-6 ps-md-5">
                  <p v-html="content?.value?.section[1].second_column_text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-bg mt-4">
      <img
        :src="baseURL + content?.value?.section[2].image.data.attributes.url"
        class="w-100 z-index-1 position-relative"
        alt="Gallery image"
      />
    </section>

    <section class="mb-5">
      <div class="container">
        <div class="row">
          <div class="col-xxl-7 col-lg-8">
            <div class="text-bg-on-left p-4 p-lg-0 bg-white-on-mobile">
              <h2
                class="font-helvetica-black text-uppercase fs-48px mt-4 mb-5 pb-lg-3"
                v-html="content?.value?.section[2].title"
              ></h2>

              <div class="row">
                <div class="col-md-6">
                  <p v-html="content?.value?.section[2].first_column_text"></p>
                </div>
                <div class="col-md-6 ps-md-5">
                  <p v-html="content?.value?.section[2].second_column_text"></p>
                </div>
              </div>
              <div class="btn-group mt-4">
                <button     data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight" type="button" class="btn btn-outline-primary lsp-1 rounded-0">
                  Jetzt loslegen
                </button>
                <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    type="button"
                    class="btn btn-primary border-primary rounded-0"
                    aria-label="Right arrow"
                >
                  <RightArrow style="scale: 0.7" />
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5 mt-5 pt-lg-5" id="p-4">
      <div class="container mt-5 pt-lg-5">
        <div class="row">
          <div class="col-xxl-7 col-lg-8 ms-lg-auto">
            <div class="text-bg-on-left to-right without-line mt-5 text-white p-4 p-lg-0">
              <div class="row align-items-end">
                <div class="col-md-6" v-for="(item, i) in content?.value?.address" :key="i">
                  <h4 class="lead font-helvetica-black">{{ item.title }}</h4>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6" v-for="(item, i) in content?.value?.address" :key="i">
                  <address v-html="item.location"></address>
                  <a
                    class="d-block text-white text-decoration-none"
                    v-if="item.phone"
                    :href="'tel:' + item.phone"
                    >{{ item.phone }}</a
                  >
                  <a href="item.website" class="d-block text-white text-decoration-none" v-if="item.website">{{item.website}}</a>
                  <a
                    class="d-block text-white text-decoration-none"
                    v-if="item.email"
                    :href="'mailto:' + item.email"
                    >{{ item.email }}</a
                  >
                </div>
              </div>
              <div class="row mt-4 align-items-end">
                <div class="col-md-6" v-for="(item, i) in content?.value?.address" :key="i">
                  <img
                    :style="i === 1 ? 'transform: scale(0.8); transform-origin: left;' : ''"
                    :src="baseURL + item.logo.data.attributes.url"
                    class="mt-4 mb-5 img-fluid w-75"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.z-2 {
  z-index: 2;
}
.ms-m-70 {
  margin-left: -70px;
}
.bg-white-on-mobile {
  background-color: transparent !important;
}
.text-bg-on-left:not(.to-right)::after {
  bottom: -320px;
  right: -400px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE2NCIgaGVpZ2h0PSI4ODEiIHZpZXdCb3g9IjAgMCAxMTY0IDg4MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNDZMMTEyMCAxNjBWNTM0TDEgNTExVjQ2WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTEgMkwxMTYxLjUgMTI0LjU2MlY4ODEiIHN0cm9rZT0iI0NDQ0NCMCIgc3Ryb2tlLXdpZHRoPSI0Ii8+Cjwvc3ZnPgo=');
}
.text-bg-on-left.to-right::after {
  bottom: -340px;
  right: -520px;
  transform: scaleX(-1);
  top: -300px;
}
.text-bg-on-left.without-line::after {
  transform: scaleX(1);
  background-size: 89%;
  background-position: top right;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE5NSIgaGVpZ2h0PSIxMjc3IiB2aWV3Qm94PSIwIDAgMTE5NSAxMjc3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAxMjMuNUwxMTY2IDFIMTE5NFYxMjc2LjVIMVYxMjMuNVoiIGZpbGw9IiMxMTI2M0QiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==');
}
</style>
