<template>
  <GoogleMap
    :styles="mapStyles"
    api-key="AIzaSyA-v8p56FtZDXZNdfNaM-12yiiBocKqhfE"
    style="width: 100%; height: clamp(750px, 55vh, 900px)"
    :center="center"
    :zoom="16"
  >
    <MyMarker :options="{ position: center }" />
    <InfoWindow :options="{ position: { lat: 47.480647437175676, lng: 8.199757471164425 } }">
      <div id="contet">
        <div id="siteNotice"></div>
        <logo-gold></logo-gold>
      </div>
    </InfoWindow>
    <InfoWindow :options="{ position: { lat: 47.48242612680892, lng: 8.20842570537773 } }">
      <div id="contet">
        <div id="siteNotice"></div>
        <h2 id="firstHeading" class="font-helvetica-black text-uppercase">
          NEUSTADT MIT <br/> VIELEN EINKAUFS-<br/>MÖGLICHKEITEN
        </h2>
        <p class="lead">5 MIN</p>
      </div>
    </InfoWindow>
    <InfoWindow :options="{ position: { lat: 47.4852778, lng: 8.2069722 } }">
      <div id="contet">
        <div id="siteNotice"></div>
        <h2 id="firstHeading" class="font-helvetica-black text-uppercase">
          Historische <br />
          Altstadt
        </h2>
        <p class="lead">15 MIN</p>
      </div>
    </InfoWindow>
    <InfoWindow :options="{ position: { lat: 47.48005696699317, lng:  8.19941128543058, } }">
      <div id="contet">
        <div id="siteNotice"></div>
        <h2 id="firstHeading" class="font-helvetica-black text-uppercase">
          Wohnquartier
        </h2>
      </div>
    </InfoWindow>
    <InfoWindow :options="{ position: { lat: 47.480869517205214, lng: 8.208649994381608, } }">
      <div id="contet">
        <div id="siteNotice"></div>
        <h2 id="firstHeading" class="font-helvetica-black text-uppercase">
          Bahnhof <br/> Brugg
        </h2>
        <p class="lead">10 MIN</p>
      </div>
    </InfoWindow>
    <InfoWindow :options="{ position: { lat: 47.48460404406194, lng:  8.196504476033743, } }">
      <div id="contet">
        <div id="siteNotice"></div>
        <h2 id="firstHeading" class="font-helvetica-black text-uppercase">
          Naherholungs-<br />
          gebiet Aare
        </h2>
        <p class="lead">10 MIN</p>
      </div>
    </InfoWindow>
    <InfoWindow :options="{ position: { lat: 47.4820708402144, lng: 8.211419050320599, } }">
      <div id="contet">
        <div id="siteNotice"></div>
<!--        <h2 id="firstHeading" class="font-helvetica-black text-uppercase">-->
<!--          -->
<!--        </h2>-->
        <FHNW/>
        <p class="lead">15 MIN</p>
      </div>
    </InfoWindow>
  </GoogleMap>
</template>

<script>
import { defineComponent } from 'vue'
import { GoogleMap, Marker as MyMarker, InfoWindow } from 'vue3-google-map'
import LogoGold from './assets/LogoGold.vue'
import FHNW from "@/components/assets/FHNW.vue";

export default defineComponent({
  components: { GoogleMap, MyMarker, InfoWindow, LogoGold, FHNW },
  setup() {
    const center = { lat: 47.4805568, lng: 8.1997682 }
    const mapStyles = [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#F5F5F5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#F5F5F5'
          }
        ]
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#444444'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#BDBDBD'
          },
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'landscape',
        stylers: [
          {
            color: '#F2F2F2'
          }
        ]
      },
      {
        featureType: 'landscape.natural.landcover',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'landscape.natural.terrain',
        elementType: 'labels.text.fill',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EEEEEE'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#E5E5E5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E'
          }
        ]
      },
      {
        featureType: 'road',
        stylers: [
          {
            saturation: -100
          },
          {
            lightness: 45
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#FFFFFF'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#DADADA'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'simplified'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#ABB3BE'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#E5E5E5'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#CCCCB0'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EEEEEE'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#CCCCB0'
          }
        ]
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'labels.icon',
        stylers: [
          {
            color: '#11263D'
          },
          {
            visibility: 'on'
          }
        ]
      },
      {
        featureType: 'transit.station.rail',
        elementType: 'labels.icon',
        stylers: [
          {
            color: '#11263D'
          },
          {
            visibility: 'on'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#C9C9C9'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#11263D'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E'
          }
        ]
      }
    ]

    //
    // const mapOptions = {
    //   disableDefaultUI: true,
    // };

    return { center, mapStyles }
  }
})
</script>

<style>
.gm-ui-hover-effect {
  display: none !important;
}
.mapdiv {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 87%);
}
#firstHeading {
  font-size: 20px;
}
.gm-style-iw-c {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.gm-style-iw-c h2 {
  font-size: 16px !important;
}
.gm-style-iw-c p.lead {
  font-family: 'Helvetica Normal', Helvetica, Tahoma, Verdana, Arial, sans-serif;
  font-size: 14px;
}
</style>
