<template>
  <svg width="57" height="42" viewBox="0 0 57 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1873_5080)">
      <path d="M57 -18L4.22986e-06 -10.0654L4.66686e-06 35.3827L57 42L57 -18Z" fill="white"/>
      <g clip-path="url(#clip1_1873_5080)">
        <path d="M32.6066 25.0004C32.609 24.5861 32.6122 24.1719 32.6135 23.7579C32.6146 23.3827 32.6135 23.0075 32.6135 22.6323V22.4826C32.5573 22.4792 32.5145 22.4747 32.4716 22.4742C31.6875 22.465 30.9027 22.4737 30.1192 22.4433C29.129 22.4047 28.1387 22.352 27.1509 22.2731C26.2164 22.1986 25.2809 22.1113 24.3539 21.9771C23.2346 21.8148 22.1202 21.6158 21.0108 21.3979C19.9443 21.1884 18.8969 20.8971 17.869 20.5442C16.7561 20.1622 15.6739 19.7144 14.6939 19.0578C14.1971 18.7251 13.7408 18.3476 13.4037 17.8502C12.8364 17.013 12.868 16.1286 13.4876 15.3271C13.9181 14.7699 14.4726 14.3559 15.0746 13.9981C16.2332 13.3087 17.4893 12.8436 18.7806 12.4661C19.386 12.2891 20.0007 12.143 20.6114 11.9836C20.6492 11.9737 20.6884 11.9687 20.74 11.959C20.74 12.217 20.7438 12.4653 20.7352 12.7128C20.7342 12.7422 20.6711 12.7828 20.6293 12.7959C20.0603 12.9721 19.485 13.1302 18.9211 13.3211C18.058 13.6134 17.2255 13.975 16.4702 14.4891C16.1523 14.7057 15.8629 14.9522 15.63 15.2605C15.1755 15.862 15.1936 16.5125 15.6813 17.0901C16.0386 17.513 16.4912 17.8143 16.9739 18.0757C17.956 18.6074 19.0114 18.953 20.087 19.2453C21.4101 19.6053 22.7562 19.8596 24.114 20.0471C24.9987 20.1693 25.8874 20.2707 26.7777 20.342C27.858 20.4286 28.9416 20.4852 30.0246 20.5332C30.7765 20.5665 31.5299 20.5641 32.2828 20.5751C32.3877 20.5767 32.4929 20.5633 32.6082 20.556V17.932C33.7969 19.1053 34.9918 20.2846 36.1946 21.4721C35.0352 22.6208 33.8339 23.8108 32.6327 25.0009H32.606L32.6066 25.0004Z" fill="#11263D"/>
        <path d="M42.4504 11.8711C42.452 12.9502 42.3524 13.7772 42.0186 14.5609C41.6182 15.5006 40.964 16.1922 39.9428 16.4678C38.8576 16.7607 37.8092 16.67 36.8513 16.042C36.2411 15.6419 35.8518 15.0667 35.5904 14.4023C35.3516 13.795 35.2496 13.1624 35.2107 12.5142C35.1679 11.7992 35.2169 11.0947 35.4056 10.403C35.6159 9.63299 35.9743 8.94159 36.5746 8.39046C37.1718 7.84222 37.8818 7.55958 38.6984 7.53965C39.3405 7.52392 39.9582 7.62512 40.5338 7.92166C41.2281 8.27929 41.6885 8.84379 41.9904 9.54044C42.3455 10.36 42.448 11.2258 42.4501 11.8713L42.4504 11.8711ZM39.9082 12.1419C39.8613 11.7491 39.8309 11.3538 39.7631 10.9644C39.6901 10.5459 39.5514 10.1477 39.2657 9.81312C38.9902 9.49036 38.6087 9.48852 38.343 9.82072C38.2562 9.92927 38.1766 10.0467 38.1132 10.17C37.8917 10.5992 37.7953 11.0625 37.7562 11.5383C37.6934 12.3018 37.7477 13.0528 38.0285 13.7754C38.1302 14.0368 38.2604 14.2835 38.4716 14.4793C38.7365 14.7253 39.0208 14.7321 39.2599 14.4665C39.3917 14.3202 39.5024 14.1437 39.5794 13.9628C39.8264 13.3829 39.8709 12.7662 39.9084 12.1419H39.9082Z" fill="#11263D"/>
        <path d="M44.054 12.1396C44.4377 12.2453 44.7997 12.3389 45.1584 12.4448C46.3032 12.7833 47.4229 13.1847 48.4775 13.7456C49.1298 14.0925 49.7469 14.4894 50.2562 15.0287C50.5919 15.3845 50.8589 15.7807 50.9596 16.2655C51.0735 16.8153 50.9433 17.3192 50.6396 17.7836C50.2424 18.3903 49.6822 18.8266 49.0731 19.2078C48.2448 19.7259 47.3511 20.1108 46.4312 20.438C44.9017 20.9821 43.3269 21.3573 41.7301 21.6514C40.4639 21.8845 39.1886 22.0523 37.9086 22.1879C37.8612 22.1929 37.813 22.1934 37.7571 22.1963V20.3054C37.9339 20.2849 38.1125 20.2671 38.2906 20.2432C39.2019 20.12 40.1154 20.0101 41.0232 19.8667C42.0967 19.6971 43.1576 19.4637 44.2029 19.1653C45.1296 18.9008 46.0393 18.5909 46.8953 18.1457C47.3702 17.8987 47.8223 17.6179 48.1924 17.2272C48.4405 16.9653 48.6359 16.6753 48.686 16.3085C48.7432 15.8908 48.5906 15.5382 48.3364 15.2209C47.896 14.6711 47.3053 14.314 46.6857 13.9968C45.8862 13.5872 45.0423 13.2873 44.1816 13.0319C44.0836 13.0028 44.0484 12.9635 44.0522 12.8617C44.0604 12.6313 44.0548 12.4005 44.0548 12.1402L44.054 12.1396Z" fill="#11263D"/>
        <path d="M26.2922 11.7932C26.5201 12.0255 26.7664 12.2353 26.9623 12.4836C27.7395 13.4689 27.5382 14.9928 26.5484 15.7738C25.841 16.332 25.0239 16.5785 24.1312 16.6225C23.1565 16.6703 22.2915 16.3727 21.5216 15.8019C21.3017 15.6388 21.1167 15.4304 20.9053 15.2329C21.3643 14.7636 21.7988 14.3192 22.2149 13.8937C22.4816 14.0874 22.7231 14.2956 22.9949 14.4529C23.4241 14.7015 23.8967 14.7568 24.3794 14.6278C24.8735 14.4956 25.1293 14.074 25.0468 13.5594C24.9781 13.1304 24.7364 12.8881 24.2934 12.8496C23.972 12.8216 23.6475 12.8305 23.3243 12.8237C23.2361 12.8218 23.148 12.8234 23.0511 12.8234V11.3147C23.3722 11.3147 23.6858 11.3208 23.9992 11.3121C24.1437 11.3079 24.292 11.2911 24.4302 11.2515C24.7784 11.1517 25.0125 10.9314 25.0489 10.5641C25.0875 10.1776 24.9491 9.8559 24.5987 9.65611C24.1091 9.37661 23.6169 9.44216 23.1494 9.70802C22.9026 9.8483 22.6808 10.0318 22.4303 10.208C22.0389 9.74394 21.6286 9.25758 21.2008 8.74997C21.3595 8.61731 21.5051 8.47861 21.6675 8.36324C22.2506 7.9495 22.8887 7.64484 23.611 7.58584C24.5729 7.50745 25.4962 7.63985 26.288 8.24657C26.8319 8.66345 27.1021 9.22926 27.1775 9.89497C27.2102 10.1839 27.2017 10.4673 27.1122 10.7476C26.9831 11.1514 26.7054 11.4453 26.391 11.7135C26.3609 11.7392 26.3284 11.7618 26.2981 11.7872C26.2885 11.7953 26.2824 11.8077 26.2925 11.794L26.2922 11.7932Z" fill="#11263D"/>
        <path d="M42.6633 8.61936C42.6633 7.71978 43.4013 6.99194 44.3052 7.00007C45.2173 7.00846 45.9364 7.72817 45.9356 8.63221C45.9348 9.52445 45.2037 10.2368 44.2921 10.2334C43.3787 10.23 42.6633 9.5213 42.6633 8.61963V8.61936ZM44.2882 9.45497C44.7684 9.45681 45.1452 9.09577 45.1505 8.62802C45.1561 8.1621 44.7642 7.77144 44.2951 7.77563C43.8284 7.77983 43.4519 8.14978 43.4479 8.60861C43.4437 9.07977 43.8143 9.45314 44.2879 9.45497H44.2882Z" fill="#11263D"/>
        <path d="M34.8163 13.158C34.771 12.5374 34.5548 11.986 34.131 11.524C33.2311 10.5434 31.7016 10.3124 30.5379 10.975C30.4285 11.0371 30.3279 11.1139 30.2115 11.1921C30.2115 11.1499 30.2067 11.1205 30.2123 11.093C30.2773 10.7783 30.3904 10.4823 30.584 10.2217C30.9245 9.76286 31.3869 9.53082 31.9636 9.55652C32.4585 9.57854 32.9108 9.75395 33.3398 9.99019C33.4588 10.0557 33.5775 10.1213 33.708 10.1931C34 9.64383 34.2875 9.10267 34.5857 8.54157C34.3959 8.42254 34.2207 8.29695 34.0317 8.19679C33.4063 7.8646 32.7386 7.65694 32.0307 7.58693C31.1827 7.50303 30.3822 7.64934 29.6559 8.10424C28.9166 8.56753 28.4797 9.25579 28.1945 10.0505C27.9885 10.6244 27.8838 11.2188 27.8396 11.825C27.7765 12.6895 27.8213 13.5411 28.1442 14.3594C28.4807 15.2125 29.0361 15.8782 29.8918 16.2684C30.6407 16.6097 31.4285 16.6588 32.2288 16.502C33.0187 16.3473 33.6885 15.9815 34.1821 15.3407C34.6797 14.695 34.8751 13.9627 34.8163 13.158ZM31.4101 14.5982C30.7775 14.5982 30.2648 14.0932 30.2648 13.4703C30.2648 12.8473 30.7775 12.3423 31.4101 12.3423C32.0427 12.3423 32.5554 12.8473 32.5554 13.4703C32.5554 14.0932 32.0427 14.5982 31.4101 14.5982Z" fill="#11263D"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1873_5080">
        <rect width="57" height="42" fill="white"/>
      </clipPath>
      <clipPath id="clip1_1873_5080">
        <rect width="38" height="18" fill="white" transform="translate(13 7)"/>
      </clipPath>
    </defs>
  </svg>
</template>