<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="147"
    height="94"
    viewBox="0 0 673 432.4"
    style="enable-background: new 0 0 673 432.4"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <polygon class="st0" points="455.9,120.8 342.8,112.6 342.4,146.6 455.9,153.8 			" />
          <polygon class="st0" points="12.5,135.2 102.7,89.8 102.7,0 9.3,64.5 			" />
          <polygon
            class="st0"
            points="269,26.5 133.4,14 133.4,130.5 271.6,140.6 271.1,116.5 290.2,117.7 272.8,136.3 271.9,150.8 
				297,152.3 297.5,144.2 294.7,136.5 311.3,119 312.2,103.3 270.7,100.7 			"
          />
          <polygon class="st0" points="332.3,244.7 355.9,243.7 355.9,183.7 332.3,183 			" />
          <polygon class="st0" points="360.4,243.5 384.1,242.5 384.1,184.5 360.4,183.8 			" />
          <polygon class="st0" points="388.6,242.3 412.3,241.3 412.3,185.2 388.6,184.6 			" />
          <polygon class="st0" points="416.8,241.1 440.5,240.1 440.5,186 416.8,185.3 			" />
          <polygon class="st0" points="445,239.9 468.6,238.9 468.6,186.8 445,186.1 			" />
          <polygon class="st0" points="473.1,238.7 496.8,237.7 496.8,187.5 473.1,186.9 			" />
          <polygon class="st0" points="501.9,237.5 525.5,236.5 525.5,188.3 501.9,187.7 			" />
          <polygon class="st0" points="530,236.3 553.7,235.3 553.7,189.1 530,188.4 			" />
          <polygon class="st0" points="558.2,235.1 581.9,234.1 581.9,189.9 558.2,189.2 			" />
          <polygon class="st0" points="586.4,233.9 610.1,232.9 610.1,190.6 586.4,190 			" />
          <polygon class="st0" points="614.6,232.7 638.2,231.7 638.2,191.4 614.6,190.7 			" />
          <polygon class="st0" points="642.7,191.5 642.7,231.5 666.4,230.5 666.4,192.2 			" />
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M112.7,270.6L6.4,247.4L0,103.3l2.5-0.1l6.3,142.2l101.3,22.1v-98.8l-88.4,12.7L13.8,69.2L112.7,3v162.8
					l5.6-0.8l0.1,0l163.9,5.8l0.7,47.9H123.4v48.6l159.2-7.5l0.1,2.5l-161.8,7.6v-53.8h159.6l-0.7-43l-161.4-5.8l-5.7,0.8V270.6z
					 M16.4,70.5l7.6,108l86.1-12.4V7.7L16.4,70.5z"
            />
          </g>
          <g>
            <path
              class="st1"
              d="M493.8,253.2l-0.1-2.4l176.8-7.5V186l-322.3-8.8l1.8-74.5l122.3-23.9l0.2,0L673,105v140.6l-1.2,0
					L493.8,253.2z M350.7,174.8l319.8,8.7v-76.4L472.4,81.2l-120,23.4L350.7,174.8z"
            />
          </g>
          <g>
            <path
              class="st1"
              d="M321.9,260.5l-2.5-106.4l-194.8-11.9L123.7,2.4L282.2,17v132.4l37.1,2.3l-0.7-32.3l146.6,12.2V163
					l-143.4-8.8l2.4,103.7l169.5-7.2l0.1,2.4L321.9,260.5z M321.8,151.8l141,8.6v-26.6l-141.7-11.8L321.8,151.8z M127,139.9
					l152.8,9.3v-130L126.1,5.1L127,139.9z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          class="st1"
          d="M17.6,314.6h57.6c7.9,0,12.6,0.7,16.4,2.5c5.1,2.5,9.4,9.2,9.4,16.8c0,6.5-2.7,13.5-10.7,15.8v0.2
			c4.1,1.2,7.4,4.7,7.9,10.7l1.2,15.1c0.3,3.1,1.6,6,3.4,8.1H72.9c-1-2-1-5-1.5-13.1c-0.3-5.1-2-8.2-7.1-8.2H45v21.3H17.6V314.6z
			 M45,345.1h19.4c5.3,0,7.6-2.1,7.6-6.5c0-5.2-3.4-6.5-8-6.5H45V345.1z"
        />
        <path
          class="st1"
          d="M109.5,314.6h27.4v41.9c0,8.9,5.1,11.7,14.1,11.7c8.9,0,14.1-2.8,14.1-11.7v-41.9h27.4v43
			c0,22-13.3,28.7-41.4,28.7c-28.1,0-41.4-6.7-41.4-28.7V314.6z"
        />
        <rect x="140.4" y="314.6" class="st1" width="21" height="12.8" />
        <path class="st1" d="M222.5,332.1h-25.3v-17.5h78v17.5h-25.3v51.8h-27.4V332.1z" />
        <path
          class="st1"
          d="M297,354.5c-13.4-2-18.9-9.8-18.9-19.5c0-17.9,17.6-22.8,40.1-22.8c30.8,0,43.6,8.1,44.5,22.7h-31.8
			c0-2.8-1.6-4.6-4.2-5.5c-2.4-1.1-5.5-1.5-8.5-1.5c-8.1,0-11,2-11,4.9c0,1.9,0.9,3.2,3.6,3.6l32.7,4.8c13.8,2,22.3,8.4,22.3,20.2
			c0,16.9-13.8,24.8-44.5,24.8c-21,0-44-2.9-44.1-23.3h33c0.1,2.3,1,3.9,2.9,4.9c2,1,5,1.5,9.3,1.5c8.5,0,10.9-2.3,10.9-5.6
			c0-2-1.3-4.1-4.9-4.7L297,354.5z"
        />
        <path
          class="st1"
          d="M463.9,356.9c-1.7,9.1-4.8,16.5-11.6,21.5c-6.7,5-17.2,7.9-33.9,7.9c-16.6,0-47.3-1.2-47.3-37.1
			c0-35.9,30.7-37.1,47.3-37.1c16.5,0,41.9,2.1,45.5,29.6h-30.8c-0.8-4.9-4.1-12.1-14.6-12.1c-11.2,0-18.2,5.5-18.2,19.6
			c0,14.1,6.9,19.6,16.9,19.6c8.6,0,13.8-3.6,16-11.9H463.9z"
        />
        <path
          class="st1"
          d="M531.7,356.2H501v27.6h-27.4v-69.3H501v24.2h30.7v-24.2h27.4v69.3h-27.4V356.2z"
        />
        <path class="st1" d="M572.8,314.6h27.4v69.3h-27.4V314.6z" />
        <path
          class="st0"
          d="M629.2,324.8h14v17.6h17.6v14h-17.6v17.6h-14v-17.6h-17.6v-14h17.6V324.8z"
        />
        <path
          class="st1"
          d="M31.1,428.1L31.1,428.1c-2.1,2.9-4.5,4.3-8.4,4.3c-6.8,0-10.5-4.3-10.5-10.5c0-6,4.1-10.5,10.7-10.5
			c5.6,0,9.1,3.1,9.7,6.8h-2.2c-0.8-3.4-3.8-4.9-7.4-4.9c-5.5,0-8.5,3.3-8.5,8.6c0,5.4,2.7,8.6,8.1,8.6c6,0,7.9-3.4,8.5-7h-7.3v-1.9
			h9.2v10.3h-1.6L31.1,428.1z"
        />
        <path class="st1" d="M39.9,411.9h15.9v1.9H42v6.8h13.3v1.9H42v7.5H56v1.9H39.9V411.9z" />
        <path
          class="st1"
          d="M75.2,417.6c-0.3-3.4-3.1-4.3-6.3-4.3c-3.2,0-5.7,1.3-5.7,3.5c0,2.6,2.4,3.2,7.3,4c3.6,0.6,7.3,1.5,7.3,5.3
			c0,4.9-4.5,6.2-8.3,6.2c-5.1,0-8.8-1.7-9.1-7.1h2.1c0.4,4.2,3.2,5.2,7,5.2c3,0,6.1-0.8,6.1-4c0-3.1-3.6-3.4-7.3-4
			c-4.8-0.8-7.3-2.1-7.3-5.3c0-4.4,4-5.8,7.9-5.8c4.1,0,8.2,1.5,8.3,6.2H75.2z"
        />
        <path
          class="st1"
          d="M103.2,424.3c-0.4,4.5-4.1,8.1-9.9,8.1c-6.5,0-10.6-4.1-10.6-10.5c0-4.6,2.8-10.5,10.6-10.5
			c4.4,0,9.1,2,9.7,7.3h-2.1c-0.6-3.8-4-5.3-7.6-5.3c-5,0-8.4,2.8-8.4,8.6c0,4.5,2.3,8.6,8.4,8.6c4.8,0,7.1-2.4,7.8-6.2H103.2z"
        />
        <path
          class="st1"
          d="M109.1,411.9h2.1v8.5h14.4v-8.5h2.1v20h-2.1v-9.6h-14.4v9.6h-2.1V411.9z"
        />
        <path class="st1" d="M134.6,411.9h2.1v20h-2.1V411.9z" />
        <path
          class="st1"
          d="M163.3,424.3c-0.4,4.5-4.1,8.1-9.9,8.1c-6.5,0-10.6-4.1-10.6-10.5c0-4.6,2.8-10.5,10.6-10.5
			c4.4,0,9.1,2,9.7,7.3h-2.1c-0.6-3.8-4-5.3-7.6-5.3c-5,0-8.4,2.8-8.4,8.6c0,4.5,2.3,8.6,8.4,8.6c4.8,0,7.1-2.4,7.8-6.2H163.3z"
        />
        <path
          class="st1"
          d="M169.2,411.9h2.1v8.5h14.4v-8.5h2.1v20h-2.1v-9.6h-14.4v9.6h-2.1V411.9z"
        />
        <path class="st1" d="M200.4,413.8h-7.8v-1.9h17.8v1.9h-7.8v18.1h-2.1V413.8z" />
        <path
          class="st1"
          d="M215.4,411.9h15.9v1.9h-13.8v6.8h13.3v1.9h-13.3v7.5h14.1v1.9h-16.1V411.9z"
        />
        <path class="st1" d="M253.8,413.8H246v-1.9h17.8v1.9h-7.8v18.1h-2.1V413.8z" />
        <path
          class="st1"
          d="M268.8,411.9h10.9c3.4,0,6.5,1.2,6.5,5.4c0,2.1-1.3,4.3-3.9,4.8v0.1c2.8,0.4,3.4,2.3,3.6,5.5
			c0.1,1.3,0.2,3.7,0.7,4.1h-2.2c-0.4-0.4-0.5-1.9-0.5-4.1c-0.1-3.5-1.4-4.7-4.8-4.7h-8v8.8h-2.1V411.9z M270.9,421.2h8.8
			c2.3,0,4.3-0.8,4.3-3.9c0-2.9-2.2-3.5-4.1-3.5h-9V421.2z"
        />
        <path class="st1" d="M292.4,411.9h2.1v20h-2.1V411.9z" />
        <path class="st1" d="M301.3,411.9h15v1.9h-12.9v6.8h12.3v1.9h-12.3v9.4h-2.1V411.9z" />
        <path class="st1" d="M321.4,411.9h15v1.9h-12.9v6.8h12.3v1.9h-12.3v9.4h-2.1V411.9z" />
        <path class="st1" d="M347.3,413.8h-7.8v-1.9h17.8v1.9h-7.8v18.1h-2.1V413.8z" />
        <path
          class="st1"
          d="M386.2,425.8h-10.5l-2.7,6.1h-2.2l9.1-20h2.4l9.1,20h-2.4L386.2,425.8z M380.9,414l-4.5,9.9h8.8L380.9,414z"
        />
        <path
          class="st1"
          d="M413.8,424.1c0,3.6-1,8.2-8.9,8.2c-7.9,0-8.9-4.6-8.9-8.2v-12.3h2.1v12.3c0,5.2,3.4,6.3,6.9,6.3
			c3.5,0,6.9-1.1,6.9-6.3v-12.3h2.1V424.1z"
        />
        <path class="st1" d="M420.6,411.9h15v1.9h-12.9v6.8h12.3v1.9h-12.3v9.4h-2.1V411.9z" />
        <path
          class="st1"
          d="M477.2,411.9h2.2l-6.7,20h-2.4l-6-17.1h-0.1l-5.9,17.1h-2.4l-6.6-20h2.3l5.5,17.2h0.1l5.9-17.2h2.5l5.9,17.2
			h0.1L477.2,411.9z"
        />
        <path
          class="st1"
          d="M494,411.4c5.7,0,10.9,3.4,10.9,10.5s-5.2,10.5-10.9,10.5c-5.7,0-10.9-3.4-10.9-10.5S488.3,411.4,494,411.4z
			 M494,430.5c4.4,0,8.6-2.6,8.6-8.6c0-6-4.2-8.6-8.6-8.6c-4.4,0-8.6,2.6-8.6,8.6C485.3,427.9,489.6,430.5,494,430.5z"
        />
        <path
          class="st1"
          d="M510.7,411.9h2.1v8.5h14.4v-8.5h2.1v20h-2.1v-9.6h-14.4v9.6h-2.1V411.9z"
        />
        <path
          class="st1"
          d="M535.9,411.9h2.5l14,17.1h0.1v-17.1h2.1v20h-2.4L538,414.8H538v17.1h-2.1V411.9z"
        />
        <path
          class="st1"
          d="M586.9,411.9h2.2l-6.7,20H580l-6-17.1H574l-5.9,17.1h-2.4l-6.6-20h2.3l5.5,17.2h0.1l5.9-17.2h2.5l5.9,17.2
			h0.1L586.9,411.9z"
        />
        <path class="st1" d="M594,411.9h15.9v1.9H596v6.8h13.3v1.9H596v7.5h14.1v1.9H594V411.9z" />
        <path
          class="st1"
          d="M616.1,411.9H627c3.4,0,6.5,1.2,6.5,5.4c0,2.1-1.3,4.3-3.9,4.8v0.1c2.8,0.4,3.4,2.3,3.6,5.5
			c0.1,1.3,0.2,3.7,0.7,4.1h-2.2c-0.4-0.4-0.5-1.9-0.5-4.1c-0.1-3.5-1.4-4.7-4.8-4.7h-8v8.8h-2.1V411.9z M618.2,421.2h8.8
			c2.3,0,4.3-0.8,4.3-3.9c0-2.9-2.2-3.5-4.1-3.5h-9V421.2z"
        />
        <path
          class="st1"
          d="M639.8,411.9h2.1v10.8l12.9-10.8h3l-9.9,8.3l10.6,11.7h-2.9l-9.3-10.3l-4.4,3.6v6.7h-2.1V411.9z"
        />
      </g>
    </g>
  </svg>
</template>

<style type="text/css">
.st0 {
  fill: #ccccb0;
}
.st1 {
  fill: #11263d;
}
</style>
