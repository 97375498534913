<script setup>
import RightArrow from '@/components/assets/RightArrow.vue'
import PlanBuilding from '@/components/assets/PlanBuilding.vue'
import DownloadIcon from '@/components/assets/DownloadIcon.vue'
import { useRoute, useRouter } from 'vue-router'
// import { RouterLink } from 'vue-router'
import { onMounted, reactive, onUnmounted } from 'vue'

let sectionObserver = null;

onMounted(() => {
  const section = document.querySelector('.section-angebot');

  sectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {

       let actives = document.querySelectorAll('.router-link-exact-active')
        actives = Array.prototype.slice.call(actives)
        actives.forEach(e => {
          if(e.innerText === 'ANGEBOT') {
            e.classList.remove("active")
          }
          else {
            e.classList.add('active')
          }
        })
      } else {
        let actives = document.querySelectorAll('.router-link-exact-active')
        actives = Array.prototype.slice.call(actives)
        actives.forEach(e => {
          if(e.innerText === 'PREISE') {
            e.classList.remove("active")
          }  else {
            e.classList.add('active')
          }
        })
      }
    })
  }, {
    threshold: 0.5 // Adjust this value to determine how much of the element should be in view before the callback is triggered
  });

  sectionObserver.observe(section);
});

onUnmounted(() => {
  if (sectionObserver) {
    sectionObserver.disconnect();
  }
});


import axios from 'axios'

const route = useRoute();
const router = useRouter()


const baseURL = import.meta.env.VITE_URL
let content = reactive([])
let table = reactive([])

const formData = reactive({
  selectedTable: route.hash.replace('#', '') || 'patiohaus',
  houseNumber: null
})

const emitIndexOfHouse = (index) => {
  formData.houseNumber = index + 1
}
const removeIndexOfHouse = () => {
  formData.houseNumber = null
}



const downloadItem = (url) => {
  axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = url.split('/')[4]
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(console.error)
}


const getContent = () => {
  axios
    .get(`${baseURL}/api/angebots?populate=*,section.image,gallery_images.image`)
    .then(async (res) => {
      content.value = await res.data.data[0].attributes
      // console.log(content)
    })
}
const getTable = () => {
  axios.get(`${baseURL}/api/angebot-tables?populate=*,patiohauser.Plan_pdf,loftwohnungen.Plan_pdf,atelierlofts.Plan_pdf,etagenwohnung.Plan_pdf,maisonettewohnung.Plan_pdf,gewerbe.Plan_pdf`).then(async (res) => {
    table.value = await res.data.data[0].attributes
    // tableData.data = table.value[formData.selectedTable]
    updateTable()
  })

}

const tableData = reactive({
  data: []
})

// const selected = computed(() => formData.selectedTable)


const updateTable = (changeRoute) => {
  let mapper = {
    'patiohaus': 'patiohauser',
    "loftwohnung" : 'loftwohnungen',
    "atelier-loft" : 'atelierlofts',
    "etagenwohnung" : 'etagenwohnung',
    "maisonette-wohnung" : 'maisonettewohnung',
    "gewerbe" : 'gewerbe',
  };

  tableData.data = table.value[mapper[formData.selectedTable]]
  if(changeRoute) {
    router.push({ hash: '#' + formData.selectedTable })
  }

}

onMounted(getContent)
onMounted(getTable)
// onMounted(updateTable)
</script>

<template>
  <div>
    <section class="container" v-if="content.value">
      <h1
        class="text-secondary font-helvetica-normal mt-3 fs-60px"
        v-html="content?.value?.section[0].title"
      ></h1>
      <div class="row mt-lg-5 pt-4">
        <div class="col-md-10 offset-md-2">
          <p class="lead" v-html="content?.value?.section[0].first_column_text"></p>
        </div>
      </div>
    </section>

    <section class="has-left-line mt-lg-5 pt-lg-5 position-relative" v-if="content.value">
      <div class="container pt-4 pt-md-0">
        <div class="row gallery-clip-path">
          <div class="col-sm-6 mb-4 mb-md-0">
            <img
              :src="baseURL + content?.value?.gallery_images[0].image.data.attributes.url"
              class="img-fluid h-100 object-fit-cover"
              alt="Gallery image 1"
            />
          </div>
          <div class="col-sm-6 mb-5 mb-md-0">
            <img
              :src="baseURL + content?.value?.gallery_images[2].image.data.attributes.url"
              class="img-fluid mb-4 h-50 w-100"
              alt="Gallery image 2"
            />
            <img
              :src="baseURL + content?.value?.gallery_images[1].image.data.attributes.url"
              class="img-fluid h-50 w-100"
              alt="Gallery image 3"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section-angebot text-white mt-lg-5 pt-md-5 position-relative" ref="section" :id="formData.selectedTable">
      <div class="container py-md-5">
        <div class="row align-items-center py-5" id="radio-buttons">
          <div class="col-md-5 px-5 px-xxl-4 position-relative">
            <plan-building :number="formData.houseNumber" :selected="formData.selectedTable"></plan-building>
          </div>
          <div class="col-md-7 px-0 pt-5 pb-md-5">
            <h2 class="font-helvetica-black fs-60px">ANGEBOT</h2>
            <div class="input-group has-validation d-flex mb-5 mt-5">
              <div class="form-check form-check-inline">
                <input
                  @change="updateTable('changeRoute')"
                  v-model="formData.selectedTable"
                  type="radio"
                  name="inlineRadioOptions"
                  id="Patiohäuser"
                  value="patiohaus"
                />
                <label class="form-check-label" for="Patiohäuser">Patiohäuser</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  @change="updateTable"
                  v-model="formData.selectedTable"
                  type="radio"
                  name="inlineRadioOptions"
                  id="Loftwohnungen"
                  value="loftwohnung"
                />
                <label class="form-check-label" for="Loftwohnungen">Loftwohnungen</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  @change="updateTable"
                  v-model="formData.selectedTable"
                  type="radio"
                  name="inlineRadioOptions"
                  id="Atelier-Lofts"
                  value="atelier-loft"
                />
                <label class="form-check-label" for="Atelier-Lofts">Atelier-Lofts</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  @change="updateTable"
                  v-model="formData.selectedTable"
                  type="radio"
                  name="inlineRadioOptions"
                  id="etage"
                  value="etagenwohnung"
                />
                <label class="form-check-label" for="etage">Etagenwohnung</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  @change="updateTable"
                  v-model="formData.selectedTable"
                  type="radio"
                  name="inlineRadioOptions"
                  id="maisonett"
                  value="maisonette-wohnung"
                />
                <label class="form-check-label" for="maisonett">Maisonettewohnung</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  @change="updateTable"
                  v-model="formData.selectedTable"
                  type="radio"
                  name="inlineRadioOptions"
                  id="gewerbe"
                  value="gewerbe"
                />
                <label class="form-check-label" for="gewerbe">Gewerbe</label>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-white table-hover">
                <thead>
                  <tr>
                    <th scope="col" class="text-nowrap">Nr.</th>
                    <th scope="col" class="text-nowrap">Geschoss</th>
                    <th scope="col" class="text-nowrap">Zimmer</th>
                    <th scope="col" class="text-nowrap">NWF</th>
                    <th scope="col" class="text-nowrap">
                      Fläche <br />
                      Aussenraum
                    </th>
                    <th scope="col" class="text-nowrap">Verkaufspreis</th>
                    <th scope="col" class="text-center">Plan</th>
                  </tr>
                </thead>
                <tbody class="mt-5">
                  <tr v-for="(item, index) in tableData.data"
                      @mouseover="emitIndexOfHouse(index)"
                      @mouseleave="removeIndexOfHouse"
                      :key="index">

                    <th class="text-nowrap" scope="row">{{ item.Nr }}</th>
                    <td class="text-nowrap">{{ item.Geschoss }}</td>
                    <td class="text-nowrap">{{ item.Zimmer }}</td>
                    <td class="text-nowrap">{{ item.NWF }}</td>
                    <td class="text-nowrap">{{ item.Flacheaussenraum }}</td>
                    <td class="text-nowrap">{{ item.Verkaufspreis
                      }}</td>
                    <td class="text-center" style="min-width: 117px;">
                      <a @click.prevent="downloadItem(baseURL + item.Plan_pdf.data.attributes.url)" v-if="item.status === 'available'" class="pdf-button text-decoration-none"
                        ><download-icon
                      /></a>
                      <p class="mb-0" v-else-if="item.status === 'reserved'">reserviert</p>
                      <p class="mb-0" v-else-if="item.status === 'sold'">verkauft</p>
                      <p class="mb-0" v-else>-</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex my-md-4">
              <div class="btn-group mt-5 mb-5 mb-lg-5 ms-md-auto" role="group">
                <button type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight" class="btn btn-outline-secondary rounded-0">
                  zum Anmelde-Formular
                </button>
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  type="button"
                  class="btn btn-secondary rounded-0"
                  aria-label="Right arrow"
                >
                  <RightArrow style="scale: 0.7" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="my-5 pt-lg-5 mb-md-0" v-if="content.value">
      <div class="container has-right-line position-relative">
        <div class="row mb-lg-5 pb-5">
          <div class="col-md-8">
            <p class="lead" v-html="content?.value?.section[0].second_column_text"></p>
          </div>
        </div>
        <div class="row masonry-clip-path">
          <div class="col-sm-6 pb-4 pb-md-0">
            <div class="mb-4 h-50 position-relative">
              <img
                :src="baseURL + content?.value?.gallery_images[3].image.data.attributes.url"
                class="w-100 h-full img-fluid"
                alt="Gallery image"
              />
              <a
                href="https://biganto.com/tour/37612"
                target="_blank"
                class="d-block bg-primary text-light position-absolute left-0 top-0 text-decoration-none mt-5 p-3 pe-4"
              >
                <small class="text-secondary position-relative"> Panorama-Tour </small>
                <br />
                <strong class="font-helvetica-black position-relative"
                  >ATELIER-<br />
                  HAUS</strong
                >
                <span class="btn tour-button text-primary btn-sm position-absolute bottom-0"></span>
              </a>
            </div>
            <img
              :src="baseURL + content?.value?.gallery_images[4].image.data.attributes.url"
              class="w-100 img-fluid h-50"
              alt="Gallery image"
            />
          </div>
          <div class="col-sm-6">
            <img
              :src="baseURL + content?.value?.gallery_images[5].image.data.attributes.url"
              class="w-100 img-fluid mb-4"
              alt="Gallery image"
            />
            <div class="position-relative">
              <img
                :src="baseURL + content?.value?.gallery_images[6].image.data.attributes.url"
                class="w-100 h-100 img-fluid mb-4"
                alt="Gallery image"
              />
              <a
                href="https://biganto.com/tour/37768"
                target="_blank"
                class="d-block bg-primary text-light position-absolute left-0 top-0 text-decoration-none mt-5 p-3 pe-4"
              >
                <small class="text-secondary position-relative"> Panorama-Tour </small>
                <br />
                <strong class="font-helvetica-black position-relative"
                  >PATIO-<br />
                  HAUS</strong
                >
                <span class="btn tour-button text-primary btn-sm position-absolute bottom-0"></span>
              </a>
            </div>
            <img
              :src="baseURL + content?.value?.gallery_images[7].image.data.attributes.url"
              class="w-100 img-fluid"
              alt="Gallery image"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.form-check-inline {
  margin-right: 40px;
  margin-bottom: 16px;
}
.form-check-inline > input:not(:checked) {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
}
.form-check-inline > input {
  margin-right: 7px;
  border: 2px solid var(--bs-secondary);
  float: left;
  margin-left: -1.5em;
  margin-top: 0.25em;
  vertical-align: top;
  width: 1em;
  height: 1em;
  border-radius: 50px;
}
.form-check-inline > input:focus {
  box-shadow: none !important;
}

.section-angebot {
  overflow: hidden;
  background-color: var(--bs-primary);
}
#radio-buttons {
  accent-color: var(--bs-secondary);
}
a.bg-primary {
  background-color: transparent !important;
  z-index: 1;
  padding: 2rem 1rem !important;
}
a.bg-primary::before {
  display: block;
  content: '';
  inset: 0;
  clip-path: polygon(0 0, 100% 13%, 100% 86%, 0% 100%);
  background-color: var(--bs-primary);
  position: absolute;
}
a.bg-primary .btn {
  transition: right 0.3s ease-in-out;
  right: 15px;
}
a.bg-primary:hover .btn {
  right: 12px;
}

@media screen and (min-width: 768px) {
  .section-angebot {
    background-color: transparent;
  }
  .section-angebot::after {
    content: '';
    position: absolute;
    display: block;
    top: -4px;
    bottom: 0px;
    right: calc(50% - 820px);
    min-width: 1000px;
    background-repeat: no-repeat;
    background-position: 70% -4%;
    background-size: auto 100%;
    z-index: 1;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIxNSIgaGVpZ2h0PSIxMTc5IiB2aWV3Qm94PSIwIDAgMTIxNSAxMTc5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS41IDEwNDguMTFWOTguODg0NUwxMjEzLjUgMS42MjUxOVYxMTQ0LjM4TDEuNSAxMDQ4LjExWiIgZmlsbD0id2hpdGUiIHN0cm9rZT0iI0NDQ0NCMCIgc3Ryb2tlLXdpZHRoPSIzIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDAgMTMyVjIyOS41VjIzNFYxMDkwLjVMMTE2MCAxMTc4LjVWMjM0VjIyOS41SDExNTkuOTRMMTE1Ny41IDQ1LjVMNDAgMTMyWiIgZmlsbD0iIzExMjYzRCIvPgo8L3N2Zz4K');
    min-height: 1110px;
  }
  .section-angebot > * {
    position: relative;
    z-index: 2;
  }

  .gallery-clip-path {
    clip-path: polygon(0 11%, 100% 0, 100% 89%, 0% 100%);
    position: relative;
  }
  .masonry-clip-path {
    clip-path: polygon(0 0, 100% 11%, 100% 100%, 0 89%);
    position: relative;
  }
  .masonry-clip-path .img-fluid:not(.h-50) {
    max-height: 33.3%;
    /* image-rendering: pixelated; */
  }

  .has-left-line::after,
  .has-left-line::before,
  .has-right-line::before,
  .has-right-line::after {
    content: '';
    position: absolute;
    display: block;
  }
  .has-right-line::after {
    top: -120px;
    bottom: -25px;
    right: 40px;
    background-color: var(--bs-secondary);
    width: 3px;
  }
  .has-right-line::before {
    background-color: var(--bs-secondary);
    bottom: 115px;
    height: 3px;
    width: 2300px;
    right: 34px;
    rotate: 7deg;
  }
  .has-left-line::after {
    top: 70px;
    left: calc(50% - 40px);
    bottom: -117px;
    background-color: var(--bs-secondary);
    width: 3px;
  }
  .has-left-line::before {
    width: 1320px;
    top: 70px;
    right: calc(50% + 37px);
    background-color: var(--bs-secondary);
    height: 3px;
    transform-origin: right;
    rotate: -3.9deg;
  }
}
.object-fit-cover {
  object-fit: cover;
}
.table-hover tbody tr:hover {
  background-color: var(--bs-secondary);
  color: var(--bs-primary);
}
thead {
  position: sticky;
  top: 0;
  background-color: var(--bs-primary);
}
.table-responsive {
  min-height: 340px;
  position: relative;
  overflow-x: visible;
  overflow-y: auto;
}
thead th {
  color: var(--bs-secondary);
  font-weight: normal;
  letter-spacing: 1px;
}
.table > :not(caption) > * > * {
  border-color: rgba(204, 204, 176, 0.3);
  font-size: clamp(12px, 1vw, 100%);
}
.form-check-label {
  font-size: clamp(12px, 1vw, 100%);
}
.pdf-button {
  color: #ffffff;
  cursor: pointer;
}
tr:hover .pdf-button {
  color: var(--bs-primary);
}
.tour-button {
  background-color: transparent !important;
  border: none;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom right;
  width: 40px;
  height: 38px;
  margin-top: -4px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCAzOCA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMC44MjE1MzNMMzcuNDk5OSAzLjUwMDFWMzguMzIxNEwwIDQxVjAuODIxNTMzWiIgZmlsbD0iI0NDQ0NCMCIvPgo8cGF0aCBkPSJNMjAuNzU1NCAyOC4wNTQ0TDE4LjgwNjYgMjguMDU0NEwyNC42NDc1IDIwLjcxOTZMMTguODA2NiAxMy4zODQ4TDIwLjc1NTQgMTMuMzg0OEwyNi41OTYzIDIwLjcxOTZMMjAuNzU1NCAyOC4wNTQ0WiIgZmlsbD0iIzExMjYzRCIvPgo8cGF0aCBkPSJNMjQuNjQ3IDE5Ljc5NDdMOC40ODE4IDE5Ljc5NDdMOC40ODE4IDIxLjU2NTFMMjQuNjQ3IDIxLjU2NTFMMjQuNjQ3IDE5Ljc5NDdaIiBmaWxsPSIjMTEyNjNEIi8+Cjwvc3ZnPgo=');
}
.h-full {
  max-height: unset !important;
  height: 100% !important;
  object-fit: cover;
  object-position: left;
}
</style>
